.header {
    position: fixed;
    top: 0;
    z-index: 10;
    background-color: $white;
    color: $black;
    padding: 20px 0px;
    width: 100%;
    transition: all $timming ease-in-out;
    // border-bottom: 1px solid $gray-25;

    @include device-lg("min") {
        height: 76px;
    }

    &-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include device-lg("min") {
            height: 44px;
            margin-top: -9px;
        }

        &__logo-corporative {
            display: flex;
            align-self: center;

            a {
                display: flex;
                img {
                    filter: invert(1);
                    width: 140px;
                    position: relative;
                    z-index: 3;
                    height: 100%
                }
                p {
                    @include font-size(16, 18, 400, 1);
                    padding-left: 13px;
                    // margin-left: 16px;
                    // border-left: 0.65px solid $black;
                    margin-top: auto;
                    margin-bottom: auto;
                }
            }

        }

        &__navigation-corporative {
            ul {
                display: flex;
                align-items: flex-start;
                gap: 24px;
                padding-top: 14px;

                li {
                    display: flex;
                    align-items: flex-start;
                }

                a {
                    padding-bottom: 14px;
                }

                a.--active,
                a.active,
                a.ancestor:not(.is_root) {
                    border-bottom: 2px solid $black;
                    font-weight: 700;
                }
            }
        }

        &__language-corporative {
            display: flex;
            align-items: center;

            .lang.ego-form__field {
                width: 60px;
                height: 24px;
                padding: 0 10px;
                margin-left: 12px;

                .ego-form__field__select-dropdown {
                    width: calc(100% + 20px);
                    margin-left: -10px;
                    padding: 0;

                    ul li.option {
                        padding: 0 10px;
                        transition: background-color $timming ease-in-out;

                        &:not(.--selected):hover {
                            background-color: $gray-700 !important;
                        }
                    }
                }
            }
        }

        &__logo-store {
            display: flex;
            align-items: center;
            align-self: center;
            position: relative;
            z-index: 3;

            p {
                @include font-size(16, 18, 400, 1);
                padding-left: 13px;
                // margin-left: 16px;
                // border-left: 0.65px solid $black;
            }

            img {
                filter: invert(1);
                width: 140px;
            }

            .store-text {
                margin-top: -2px;
            }
        }

        &__navigation-store {
            ul {
                display: flex;
                align-items: flex-start;
                gap: 24px;
                margin-top: 1rem;

                li {
                    display: flex;
                    align-items: flex-start;
                }

                a {
                    padding-bottom: 14px;
                    border-bottom: 2px solid transparent;
                }

                a.--active,
                a.active,
                a.ancestor:not(.is_root) {
                    border-bottom: 2px solid $black;
                    font-weight: 700;
                }
            }
        }

        &__language-store {
            display: flex;
            align-items: flex-start;

            a {
                margin-right: 22px;
                margin-top: 1rem;
                padding-bottom: 14px;

                border-bottom: 2px solid transparent;

                &.--active {
                    border-bottom: 2px solid $black;
                    font-weight: 700;
                }
            }
        }

        &__mobile {
            display: none;
        }

        a {
            @include font-size(12, 18, 400, 5);
            text-transform: uppercase;
        }

        .new-tag {
            @include font-size(8, 10, 600);
            background-color: $red;
            padding: 3px 5px;
            border-radius: 1px;
            margin-left: 8px;
            color: $white;
        }

        .ego-form__field {
            margin-bottom: 0;

            &.--select {
                .ego-form__field__select-display {
                    @include font-size(12, 24, 400);
                    padding: 0;

                    .value-placeholder {
                        margin-top: 15px;
                        @include font-size(12, 24, 400);
                        margin: 0;
                    }
                }

                .ego-form__field__select-dropdown ul li.option {
                    @include font-size(12, 24, 400);
                }
            }
        }
    }

    @include rwd(1270) {
        &-container {
            &__navigation-store,
            &__language-store {
                display: none;
            }
        }
    }

    @include rwd(1280) {
        &-container {
            &__navigation-corporative,
            &__language-corporative {
                display: none;
            }
        }
    }
}

// MENU MOBILE
.header__menu-button {
    display: none;

    @include rwd(1270) {
        &-store {
            display: block;
        }
    }

    @include rwd(1280) {
        &-corporative {
            display: block;
        }
    }

    .header-toggler {
        font-weight: 700;
        position: relative;
        z-index: 3;
        cursor: pointer;
        border: none;
        background: none;
        padding: 0;
        margin: 0;
        margin-left: 1.5rem;
        display: flex;
        align-items: center;
        &:hover,
        &:focus,
        &:active {
            outline: none;
        }
        .toggler-text {
            font-size: 0.7rem;
            color: $black;
            &:before,
            &:after {
                transition: all 0.4s ease-in-out;
            }
            &:before {
                content: "";
                visibility: visible;
                opacity: 1;
                position: absolute;
            }
            &:after {
                content: "";
                visibility: hidden;
                opacity: 0;
            }

            @include rwd(1270) {
                display: none;
            }
        }

        @include rwd(1270) {
            &.active {
                .toggler-text {
                    display: block;
                }
            }
        }
        .toggler-icon {
            width: 20px;
            height: 20px;
            position: relative;
            margin-left: 0.5rem;
            transform: rotate(0deg);
            transition: 0.5s ease-in-out;

            span {
                display: block;
                position: absolute;
                height: 2px;
                width: 100%;
                background: $black;
                border-radius: 5px;
                opacity: 1;
                left: 0;
                transform: rotate(0deg);
                transition: 0.25s ease-in-out;

                &:nth-child(1) {
                    top: 3px;
                }

                &:nth-child(2) {
                    top: 8.5px;
                    height: 2.5px;
                }

                &:nth-child(3) {
                    top: 15px;
                    height: 2px;
                }
            }
        }

        &.active {
            .toggler-text {
                &:before {
                    visibility: hidden;
                    opacity: 0;
                }

                &:after {
                    visibility: visible;
                    opacity: 1;
                }
            }

            .toggler-icon {
                span {
                    &:nth-child(1) {
                        top: 8.5px;
                        transform: rotate(135deg);
                    }

                    &:nth-child(2) {
                        opacity: 0;
                    }

                    &:nth-child(3) {
                        top: 8.5px;
                        transform: rotate(-135deg);
                    }
                }
            }
        }
    }
}

.header__secondary-nav {
    position: fixed;
    z-index: 2;
    right: 0;
    top: 0;
    bottom: 0;
    min-width: 20rem;
    width: 50%;
    background-color: $white !important;
    color: $black !important;
    visibility: hidden;
    transform: translateX(100%);
    // transition: all 300ms ease-in-out;

    .header__secondary-nav__list {
        background-color: $white !important;
        color: $black !important;

        li {
            text-transform: uppercase;
            @include font-size(12, 18, 400, 5);

            a {
                display: flex;
                justify-content: space-between;
                width: 100%;
            }
        }
    }

    .language-options {
        display: none;
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.5);
        z-index: 10;

        &.active {
            display: block;
        }

        &__content {
            background-color: $white;
            position: fixed;
            bottom: 0;
            left: 0;
            // padding: 34px 24px 62px 24px;
            padding-top: 8px;
            border-radius: 16px 16px 0px 0px;
            z-index: 11;
            width: 100vw;
            text-transform: uppercase;
            overflow-y: scroll;
            @include custom-scrollbars;
            row-gap: 0 !important;

            li {
                color: $black;
                height: 48px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                @include font-size(18, 28, 400);
                text-transform: none;

                .icon-selected {
                    display: none;
                }

                &.--selected {
                    .icon-selected {
                        display: block;
                    }
                }
            }

            .text {
                display: flex;
                justify-content: space-between;
                padding-left: 24px;
                padding-right: 24px;
                margin-bottom: 20px;
                color: $black;

                div {
                    font-size: 12px;
                    color: $black;
                }
            }

            .icon {
                margin-right: auto;
                margin-left: auto;
                margin-bottom: 21px;
            }
        }
    }

    @include rwd(1270) {
        &.open {
            visibility: visible;
            transform: translateX(0);
            height: 100vh;
            min-width: auto;
            transition: all $timming ease-in-out;
        }
        &__list {
            position: absolute;
            top: 4.5rem;
            bottom: 0;
            right: 0;
            left: 0;
            overflow-y: auto;
            overflow-x: hidden;
            background-color: $white;

            ul {
                display: flex;
                flex-direction: column;
                margin-top: 32px;
                row-gap: 40px;

                li {
                    padding: 0px 34px;
                }
            }
        }
    }

    @include device-lg {
        width: 100%;
    }
}

.header-placeholder {
    height: 104px;
    background-color: $white;

    @include device-lg {
        height: 65px;
    }
}

// DARK THEME
body.dark,
body.background-black {
    .header {
        background-color: $black;
        color: $white;
        border-bottom: none;
        padding: 20px 0px;

        &-container__logo-corporative {
            img {
                filter: invert(0);
            }
            // p {
            //     border-left: 0.65px solid $white;
            // }
        }

        &-container__navigation-corporative {
            ul {
                a.--active,
                a.active,
                a.ancestor:not(.is_root) {
                    border-bottom: 2px solid $white;
                }
            }
        }

        .ego-form__field.--select {
            .ego-form__field__select-display {
                color: $white;
            }

            .ego-form__field__select-dropdown {
                background-color: $black;
                box-shadow: none;
                border: 1px solid $gray-800;

                ul li.option {
                    color: $white;

                    &.--selected {
                        background-color: $gray-800;
                    }
                }
            }

            .caret {
                filter: invert(1);
            }
        }
    }

    .header__menu-button {
        .header-toggler {
            .toggler-icon {
                span {
                    background: $white;
                }
            }
        }
    }

    .header__secondary-nav {
        background-color: $black;
        color: $white;

        @include rwd(1270) {
            &__list {
                background-color: $black;
            }
        }
    }

    .header-placeholder {
        background-color: $black;
    }
}

// Manejo de las clases .header-black y .header-white (administrables)
.header {
    &.header-black {
        background-color: $black;
        color: $white;

        .header-container {
            &__logo-corporative {
                img {
                    filter: invert(0);
                }
                // p {
                //     border-left: 0.65px solid $white;
                // }
            }

            &__navigation-corporative {
                ul {
                    li {
                        a {
                            color: $white;
                            border-color: $white;
                        }
                    }
                    a.--active,
                    a.active,
                    a.ancestor:not(.is_root) {
                        border-color: 2px solid $white;
                    }
                }
            }

            &__language-corporative {
                .ego-form__field__select-display {
                    .value-placeholder {
                        color: $white;
                    }

                    .caret {
                        filter: invert(1);
                    }

                    .ego-form__field__select-dropdown {
                        background-color: $black;

                        ul li.option {
                            color: $white;

                            &.--selected {
                                background-color: $gray-800 !important;
                            }
                        }
                    }
                }
            }

            .header__menu-button-corporative {
                .header-toggler {
                    .toggler-icon {
                        span {
                            background-color: $white;
                        }
                    }

                    &.active {
                        .toggler-icon {
                            span {
                                background-color: $black !important;
                            }
                        }
                    }
                }
            }
        }
    }

    &.header-white {
        background-color: $white !important;
        color: $black !important;

        .header-container {
            &__logo-corporative {
                img {
                    filter: invert(1);
                }
            }

            &__navigation-corporative {
                ul {
                    li {
                        a {
                            color: $black;
                            border-color: $black;
                        }
                    }
                    a.--active,
                    a.active,
                    a.ancestor:not(.is_root) {
                        border-color: 2px solid $black;
                    }
                }
            }

            &__language-corporative {
                .ego-form__field__select-display {
                    .value-placeholder {
                        color: $black;
                    }

                    .caret {
                        filter: invert(0) !important;
                    }

                    .ego-form__field__select-dropdown {
                        background-color: $white;

                        ul li.option {
                            color: $black;
                            background-color: $white !important;

                            &.--selected {
                                background-color: $gray-800 !important;
                            }
                        }
                    }
                }
            }

            .header__menu-button-corporative {
                .header-toggler {
                    .toggler-icon {
                        span {
                            background-color: $black;
                        }
                    }

                    &.active {
                        .toggler-icon {
                            span {
                                background-color: $black !important;
                            }
                        }
                    }
                }
            }
        }
    }

    .cart-items {
        background-color: $black;

        color: $white;

        padding: 0.25rem 0.5rem;

        border-radius: 50px;

        font-weight: 700;

        margin-left: 0.25rem;
    }
}
