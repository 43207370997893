* {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body,
html {
    width: 100%;
    scroll-behavior: smooth;
    background-color: #f4f4f4;

    &.no-scroll {
        overflow: hidden;
    }
    &.block {
        pointer-events: none;
    }
}

body.dark,
body.background-white {
    background-color: $white;
}

body.background-black {
    background-color: $black;
    color: $white;
}

body.background-gray {
    background-color: $gray-25;
}

body,
button {
    font-family: "Nobel", Arial, Helvetica, sans-serif;
    color: $black;
    @include font-size(18, 24, 300);
}

.wrapper {
    display: block;
    width: 100%;
    position: relative;
    max-width: $max_width_wrapper;
    margin-left: auto;
    margin-right: auto;
    padding-left: 80px;
    padding-right: 80px;

    @include device-lg {
        padding-left: 32px;
        padding-right: 32px;
    }

    @include device-md {
        // I think 24px is the unit set on the Figma designs.
        // If 24px break something, go back to 16px and let me know.

        // padding-left: 16px;
        // padding-right: 16px;

        padding-left: 24px;
        padding-right: 24px;
    }
}

section {
    &:first-of-type {
        padding-top: 0;
    }

    &.page-top-navigation {
        margin-top: 44px;
        padding-bottom: 24px;

        & + section {
            padding-top: 0;
        }
    }
}

strong {
    font-weight: 700;
}

a {
    text-decoration: none;
    color: inherit;
}

.--with-margin-top {
    margin-top: 67px !important;

    @include device-lg("min") {
        margin-top: 75px !important;
    }
}

// This fix the issue when you don't have enough content on the page to fill the viewport.
// #store-app {
//     display: flex;
//     flex-direction: column;

//     // I set height with vh and dvh because dynamic viewport height (dvh) unit is not available in all the browsers
//     // https://caniuse.com/?search=dvh
//     height: 100vh;
//     height: 100dvh;
// }
