@use "sass:math";
@use "sass:map";

//BREAKPOINTS
// Small devices
@mixin device-sm($direction: "max") {
    // 576px
    @if $direction == "max" {
        @media (max-width: #{$screen-sm-min - 1}px) {
            @content;
        }
    }
    @if $direction == "min" {
        @media (min-width: #{$screen-sm-min}px) {
            @content;
        }
    }
}

// Medium devices
@mixin device-md($direction: "max") {
    // 720px
    @if $direction == "max" {
        @media (max-width: #{$screen-md-min - 1}px) {
            // 768px
            @content;
        }
    }
    @if $direction == "min" {
        @media (min-width: #{$screen-md-min}px) {
            @content;
        }
    }
}

// Large devices
@mixin device-lg($direction: "max") {
    // 1024px
    @if $direction == "max" {
        @media (max-width: #{$screen-lg-min - 1}px) {
            @content;
        }
    }
    @if $direction == "min" {
        @media (min-width: #{$screen-lg-min}px) {
            @content;
        }
    }
}

// Extra large devices
@mixin device-xl($direction: "max") {
    // 1440px
    @if $direction == "max" {
        @media (max-width: #{$screen-xl-min - 1}px) {
            @content;
        }
    }
    @if $direction == "min" {
        @media (min-width: #{$screen-xl-min}px) {
            @content;
        }
    }
}

// @mixin device-wrapper($direction: "max") {
//     // 1200px
//     @if $direction == "max" {
//         @media (max-width: #{$max_width_wrapper - 1px}) {
//             @content;
//         }
//     }
//     @if $direction == "min" {
//         @media (min-width: #{$max_width_wrapper}) {
//             @content;
//         }
//     }
// }

// Custom devices
@mixin rwd($screen) {
    @media (max-width: #{$screen}px) {
        @content;
    }
}

// Custom devices (min)
@mixin rwd-min($screen) {
    @media (min-width: #{$screen}px) {
        @content;
    }
}

@mixin font-size(
    $size: 16,
    $line-height: normal,
    $weight: 300,
    $letter-spacing: normal
) {
    font-size: $size + px;
    // font-size: (0.0625 * $size) + rem;
    font-weight: $weight;

    @if $line-height == normal {
        line-height: normal;
    } @else {
        line-height: math.div($line-height, $size);
    }

    @if $letter-spacing == normal {
        letter-spacing: normal;
    } @else {
        letter-spacing: #{math.div($letter-spacing, 100)}em;
        // letter-spacing: #{math.div($letter-spacing, $size)}em;
    }
}

@mixin flex-center($direction: row) {
    display: flex;
    flex-direction: $direction;
    align-items: center;
    justify-content: center;
}

@mixin flex-start($direction: column) {
    display: flex;
    flex-direction: $direction;
    align-items: flex-start;
    justify-content: flex-start;
}

@mixin image-responsive($display: block) {
    display: $display;
    max-width: 100%;
    height: auto;
}

@mixin main-grid($height: auto, $rows: auto) {
    display: grid;
    height: $height;
    align-items: center;

    @if $rows != auto {
        grid-template-rows: repeat($rows, 1fr);
    }

    grid-template-columns: repeat(3, 1fr 16px) 1fr;

    @include device-md("min") {
        grid-template-columns: repeat(5, 1fr 16px) 1fr;
    }

    @include device-lg("min") {
        grid-template-columns: repeat(11, 1fr 16px) 1fr;
    }
}

@mixin main-grid-columns(
    $cols: (
        "xl": 6,
        "lg": 4,
        "md": 2,
        "sm": 1,
    )
) {
    $col-desk: 23;
    $col-mob: 7;

    // SM
    @include device-sm {
        $columns: map.get($cols, "sm");
        $span-mob: math.floor(math.div($col-mob, $columns));
        $count: 1;

        @for $i from 1 through $columns {
            @if $i == $columns {
                > *:nth-child(#{$columns}n) {
                    grid-column: #{($col-mob - $span-mob) +
                        1} /
                        span
                        #{$span-mob};
                }
            } @else {
                > *:nth-child(#{$columns}n + #{$i}) {
                    grid-column: #{$count} / span #{$span-mob};
                }
                $count: $count + $span-mob + 1;
            }
        }
    }

    // MD
    @include device-sm("min") {
        $columns: map.get($cols, "md");
        $span-mob: math.floor(math.div($col-mob, $columns));
        $count: 1;

        @for $i from 1 through $columns {
            @if $i == $columns {
                > *:nth-child(#{$columns}n) {
                    grid-column: #{($col-mob - $span-mob) +
                        1} /
                        span
                        #{$span-mob};
                }
            } @else {
                > *:nth-child(#{$columns}n + #{$i}) {
                    grid-column: #{$count} / span #{$span-mob};
                }
                $count: $count + $span-mob + 1;
            }
        }
    }

    // LG
    @include device-md("min") {
        $columns: map.get($cols, "lg");
        $span-desk: math.floor(math.div($col-desk, $columns));
        $count: 1;

        @for $i from 1 through $columns {
            @if $i == $columns {
                > *:nth-child(#{$columns}n) {
                    grid-column: #{($col-desk - $span-desk) +
                        1} /
                        span
                        #{$span-desk};
                }
            } @else {
                > *:nth-child(#{$columns}n + #{$i}) {
                    grid-column: #{$count} / span #{$span-desk};
                }
                $count: $count + $span-desk + 1;
            }
        }
    }

    // XL
    @include device-lg("min") {
        $columns: map.get($cols, "xl");
        $span-desk: math.floor(math.div($col-desk, $columns));
        $count: 1;

        @for $i from 1 through $columns {
            @if $i == $columns {
                > *:nth-child(#{$columns}n) {
                    grid-column: #{($col-desk - $span-desk) +
                        1} /
                        span
                        #{$span-desk};
                }
            } @else {
                > *:nth-child(#{$columns}n + #{$i}) {
                    grid-column: #{$count} / span #{$span-desk};
                }
                $count: $count + $span-desk + 1;
            }
        }
    }
}

@mixin custom-scrollbars() {
    &::-webkit-scrollbar {
        width: 0.5em;
        padding-left: 10px;
    }

    &.--horizontal-scroll {
        &::-webkit-scrollbar {
            height: 0.5em;
        }
    }

    &::-webkit-scrollbar-track {
        box-shadow: none;
        -webkit-box-shadow: none;
    }

    &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.2);
        outline: 0;
        border-radius: 5px;
    }
}
