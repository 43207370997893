@use "sass:math";

.ego-form {
    width: 100%;
    margin: 0 auto;

    $form: &;

    &__field {
        width: 100%;
        position: relative;
        margin-bottom: 40px;
        background-color: transparent;
        z-index: 4;

        &.--border {
            border: 1px $gray-600 solid !important;
        }

        @mixin active-label {
            position: absolute;
            top: 7px;
            @include font-size(12, 12, 300, 0);
        }

        @mixin active-field {
            border-color: $gray-600;
            // box-shadow: 0px 0px 0px 4px rgba($black, 0.2);
            outline: none;
            outline-color: $black;
        }

        @mixin hidden-input {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
        }

        $field: &;

        &__dropdown {
            .value-placeholder {
                text-transform: none !important;
                // @include font-size(14, 20, 400, 0);
                // color: $gray-700;
            }
        }

        &__select {
            -moz-appearance: none;
            -webkit-appearance: none;
            appearance: none;
            padding-right: 45px;

            &::-ms-expand {
                display: none;
            }
        }

        &__input,
        &__select,
        &__radio-label,
        &__file {
            display: block;
            position: relative;
            z-index: 0;
            border: 1px solid $gray-200;
            background-color: $white;
            padding: 22px 16px 8px 16px;
            height: 48px;
            width: 100%;
            color: $black;
            @include font-size(14, 20, 400, 0);
            font-family: "Nobel", Arial, Helvetica, sans-serif;
            transition: all $timming ease-in-out;
            background-repeat: no-repeat;
            background-position: center right 16px;

            &:hover {
                border-color: $gray-600;
            }

            &:focus {
                @include active-field;
            }

            &:focus + label {
                @include active-label;
            }

            &::placeholder {
                color: $gray-700;
            }
        }

        &__label {
            pointer-events: none;
            position: absolute;
            top: 15px;
            left: 0px;
            padding-left: 17px;
            @include font-size(12, 18, 300, 0);
            color: $gray-700;
            user-select: none;
            cursor: text;
            width: 100%;
            // height: 100%;
            text-align: left;
            transition: all $timming ease-in-out;
            @include active-label;
        }

        &__textarea {
            padding-top: 1.4rem;
            height: 100%;
            resize: none;

            &::-webkit-input-placeholder {
                /* Chrome/Opera/Safari */
                @include font-size(14, 20, 400);
                color: $gray-700;
            }
            &::-moz-placeholder {
                /* Firefox 19+ */
                @include font-size(14, 20, 400);
                color: $gray-700;
            }
            &:-moz-placeholder {
                /* Firefox 18- */
                @include font-size(14, 20, 400);
                color: $gray-700;
            }
        }

        &.--checkbox {
            cursor: pointer;

            #{$field}__label {
                pointer-events: all;
                position: static;
                padding: 0;
                @include font-size(16, 24);
            }

            #{$field}__checkbox {
                appearance: none;
                background-color: $white;
                margin: 0 6px 0 0;
                font: inherit;
                color: currentColor;
                width: 1.5em;
                height: 1.5em;
                border: 2px solid #a7a7a8;
                border-radius: 0.4em;
                transform: translateY(-0.075em);
                display: inline-grid;
                place-content: center;
                vertical-align: middle;
                transition: all $timming ease-in-out;

                &::before {
                    content: "";
                    width: 0.8em;
                    height: 0.8em;
                    transform: scale(0);
                    box-shadow: inset 1em 1em $white;
                    background-color: CanvasText;
                    clip-path: polygon(
                        18% 41%,
                        5% 55%,
                        37% 84%,
                        100% 20%,
                        87% 7%,
                        37% 58%
                    );
                    transition: all $timming ease-in-out;
                }

                &:hover {
                    border-color: $gray;
                    background-color: $gray-200;

                    &::before {
                        box-shadow: inset 1em 1em $gray-200;
                    }
                }

                &:focus {
                    box-shadow: 0px 0px 0px 4px rgba(76, 13, 224, 0.2);
                    outline: none;
                }

                &:checked {
                    background-color: $gray;
                    border-color: $gray;

                    &::before {
                        transform: scale(1);
                    }
                }
            }

            &.--has-error {
                #{$field}__checkbox {
                    border-color: $danger !important;

                    &:checked {
                        background-color: $danger;
                    }
                }
            }

            #{$field}__error {
                position: static;
                margin: auto;
                display: table;
                margin-top: 10px;
            }
        }

        &.--radio {
            cursor: pointer;

            #{$field}__label {
                display: block;
                pointer-events: all;
                position: static;
                padding: 0;
                @include font-size(16, 24);

                &:not(:last-child) {
                    margin-bottom: 32px;
                }
            }

            #{$field}__radio {
                appearance: none;
                background-color: $white;
                margin: 0 6px 0 0;
                font: inherit;
                color: currentColor;
                width: 1.5em;
                height: 1.5em;
                border: 2px solid #a7a7a8;
                border-radius: 50%;
                transform: translateY(-0.075em);
                display: inline-grid;
                place-content: center;
                vertical-align: middle;
                transition: all $timming ease-in-out;

                &::before {
                    content: "";
                    width: 0.6em;
                    height: 0.6em;
                    border-radius: 50%;
                    transform: scale(0);
                    background-color: $white;
                    transition: transform $timming ease-in-out;
                }

                &:hover {
                    border-color: $gray;
                    background-color: $gray-200;

                    &::before {
                        box-shadow: inset 1em 1em $gray-200;
                    }
                }

                &:focus {
                    box-shadow: 0px 0px 0px 4px rgba(76, 13, 224, 0.2);
                    outline: none;
                }

                &:checked {
                    background-color: $gray;
                    border-color: $gray;

                    &::before {
                        transform: scale(1);
                    }
                }
            }

            &.--inline-radio {
                display: flex;
                flex-flow: row wrap;
                row-gap: 7px;
                column-gap: 3px;
                margin-bottom: 15px;

                #{$field}__label {
                    display: inline-block;
                    width: auto;
                    margin: 4px 0;
                }

                #{$field}__radio {
                    &::before {
                        display: none;
                    }

                    &:checked {
                        border-color: $white;
                        box-shadow: 0px 0px 0px 4px $gray;
                    }
                }
            }

            &.--has-error {
                #{$field}__checkbox {
                    border-color: $danger !important;

                    &:checked {
                        background-color: $danger;
                    }
                }
            }

            #{$field}__error {
                position: static;
                margin: auto;
                display: table;
                margin-top: 10px;
            }
        }

        &.--password {
            input {
                padding-right: 52px;
            }
        }

        &.--file {
            #{$field}__file {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
                gap: 10px;
                align-items: center;
                padding: 1.3rem 1rem 1.3rem 1rem;
                cursor: pointer;

                .attachment-control {
                    display: flex;
                    align-items: center;
                    min-height: 22px;
                }

                .attachment-file-info {
                    display: grid;
                    grid-template-columns: auto minmax(60px, 1fr);
                    align-items: center;
                    gap: 10px;

                    .attachment-file-name {
                        @include font-size(16, 16);
                        text-decoration: underline;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        overflow: hidden;
                    }

                    .attachment-file-size {
                        @include font-size(12, 12);
                        color: $gray-25;
                        min-width: 60px;

                        i {
                            color: $gray;
                            text-transform: uppercase;
                        }

                        &:empty {
                            &:before {
                                content: attr(data-placeholder);
                            }
                        }
                    }
                }

                .icon__clip {
                    height: 1rem;
                }

                .attachment-remove {
                    display: none;
                    cursor: pointer;
                    border: 0;
                    background: none;
                    outline: none;
                    padding: 0;
                    margin: 0 0 0 auto;
                    font-size: 23px;
                    line-height: 0.8;
                }
            }

            &.--has-file {
                #{$field}__file {
                    @include active-field;

                    .icon__clip {
                        display: none;
                    }

                    .attachment-remove {
                        display: block;
                    }
                }
            }

            input {
                @include hidden-input;
            }
        }

        &.--disabled {
            pointer-events: none;
            opacity: 0.4;
        }

        &__error {
            opacity: 0;
            color: $danger;
            @include font-size(12, 16, 400, 0);
            text-align: left;
            // position: absolute;
            // left: 0;
            // top: 55px;
            margin-top: 8px;
            display: flex;
            align-items: self-end;
            background-repeat: no-repeat;
            padding-left: 20px;
            transition: opacity $timming ease-in-out;

            img {
                margin-right: 9px;
            }

            strong {
                font-weight: 400;
            }

            &.--active {
                opacity: 1;
            }
        }

        &__warning {
            color: $gray;
            @include font-size(12, 16, 400, 0);
            text-align: left;
            position: absolute;
            left: 0;
            top: 55px;
            display: flex;
            background-repeat: no-repeat;
            padding-left: 20px;
            height: 16px;
            transition: opacity $timming ease-in-out;

            img {
                margin-right: 9px;
            }

            strong {
                font-weight: 400;
            }
        }

        &.--filled, &.--filled-react {
            #{$field}__input:not(:focus),
            #{$field}__select:not(:focus),
            &.--textarea:not(:focus) {
                border-color: $gray;
            }
            #{$field}__label {
                @include active-label;
            }
        }

        &.--optional,
        &.--custom-assistive {
            &::after {
                @include font-size(12, 12, 400);

                content: "OPTIONAL";
                position: absolute;
                right: 1rem;
                top: 1.1rem;
                color: $black;
                pointer-events: none;
                transition: opacity $timming ease-in-out;

                @include device-md {
                    @include font-size(10, 10, 400);
                    right: 1rem;
                    top: 1.3rem;
                }
            }

            &.--filled, &.--filled-react {
                &::after {
                    opacity: 0;
                }
            }
        }

        &.--optional {
            &::after {
                content: "OPTIONAL";
            }
        }

        &.--custom-assistive {
            $value: attr(data-assistive);
            &::after {
                content: $value;
            }
        }

        &.--has-error {
            #{$field}__input,
            #{$field}__select,
            #{$field}__file,
            &.--textarea {
                background-color: $white;
                border-color: $danger !important;
            }

            #{$field}__error {
                opacity: 1;
            }

            #{$field}__warning {
                opacity: 0;
            }

            &.--optional:after {
                display: none !important;
            }
        }

        &.--full-height {
            height: 100%;
        }

        &.--textarea {
            display: flex;
            flex-direction: column;
            width: 100%;
            border: 1px solid #E8E8E8;
            background-color: $white;
            transition: all $timming ease-in-out;
            position: relative !important;

            &:hover {
                border-color: $black;
            }

            &:focus-within {
                @include active-field;
                outline-color: $black;
            }

            textarea {
                display: block;
                font-family: "Nobel", Arial, Helvetica, sans-serif;
                @include font-size(16);
                padding: 0;
                margin: 1rem;
                margin-top: 1.2rem;
                border: 0;
                background: transparent;
                outline: none !important;
                @include custom-scrollbars;
            }

            &.--big {
                min-height: 280px;
            }

            // #{$field}__label {
                // opacity: 0.5;
                // font-style: italic;
            // }

            #{$field}__error {
                inset: 1px 1px auto auto;
                height: auto;
                width: fit-content;
                background-color: none;
                max-width: none;
                min-height: 70px;
                max-height: 50%;
            }
        }

        &.--select, &.--select-react {
            display: block;
            position: relative;
            z-index: 0;
            height: 48px;
            width: 100%;
            color: $black;
            // text-transform: uppercase;
            @include font-size(12, 24, 400, 7.5);
            font-family: "Nobel", Arial, Helvetica, sans-serif;
            transition: all $timming ease-in-out;
            cursor: default;
            border: none;
            z-index: 5;

            #{$field}__label {
                top: 13px;
            }

            &:hover {
                border-color: $gray-25;
            }

            &:focus,
            &.--open {
                @include active-field;
                z-index: 6;
            }

            &:focus + label,
            &.--open + label {
                @include active-label;
            }

            &.--filled, &.--filled-react {
                border-color: $gray-200;

                #{$field}__label {
                    top: 5px;
                }
            }

            &.--has-error {
                border-color: $danger !important;
            }

            .caret {
                content: "";
                display: inline-block;
                width: 22px;
                height: 22px;
                background: url("../images/dropdown-arrow.svg") no-repeat center
                    center;
                background-size: contain;
            }

            &.--open {
                .caret {
                    transform: rotate(180deg);
                }
            }

            #{$field}__select-display {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px 16px;
                // border-radius: 8px;
                transition: all $timming ease-in-out;
                color: $black;

                .value-placeholder {
                    @include font-size(14, 20, 400, 0);
                    margin-top: 10px;
                    // text-transform: uppercase;
                }
            }

            #{$field}__select-dropdown {
                position: relative;
                z-index: 1;
                background-color: $white;
                box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
                    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
                border: 1px solid $gray-25;
                // border-radius: 8px;
                padding: 8px 0;
                // width: 402px;
                margin-left: -3px;
                margin-top: 8px;
                max-height: 160px;
                overflow-x: auto;
                pointer-events: none;
                opacity: 0;
                transition: opacity 0.2s ease-in-out, color 0.2s ease-in-out,
                    background-color 0.2s ease-in-out;

                @include custom-scrollbars;

                ul {
                    display: flex;
                    flex-direction: column;

                    li.option {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: 38px;
                        padding: 0 16px;
                        width: 100%;

                        &:not(.--disabled):hover,
                        &:not(.--selected):hover {
                            background-color: $gray-25;
                        }

                        &.--selected {
                            background-color: $gray-200 !important;
                            color: $black;
                        }

                        &.--disabled {
                            color: $gray-25;
                            pointer-events: none;
                            cursor: not-allowed;
                        }
                    }
                }
            }

            &.--open {
                #{$field}__select-dropdown {
                    opacity: 1;
                    pointer-events: all;
                }
            }

            &.--drop-up {
                #{$field}__select-dropdown {
                    position: absolute;
                    // inset: auto 0 calc(100% + 10px) 0;
                    width: 100%;
                    box-shadow: 0px -4px 8px -2px rgba(16, 24, 40, 0.1),
                        0px -2px 4px -2px rgba(16, 24, 40, 0.06);
                }
            }
        }

        &.--select-text {
            display: inline-block;
            position: relative;
            z-index: 0;
            width: auto;
            height: 25px;
            margin-bottom: 0;
            padding: 0;
            color: $black;
            @include font-size(16, 16, 400, 0);
            font-family: "Nobel", Arial, Helvetica, sans-serif;
            transition: all $timming ease-in-out;
            cursor: pointer;
            border: 0;
            z-index: 5;

            &.--has-error {
                border-color: $danger !important;
            }

            .caret {
                content: "";
                display: inline-block;
                width: 22px;
                height: 22px;
                background: url("../images/dropdown-arrow.svg") no-repeat center
                    center;
                background-size: contain;
                transform: rotate(180deg);
            }

            #{$field}__select-display {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                padding: 0;
                transition: all $timming ease-in-out;
                color: $gray;

                .value-placeholder {
                    @include font-size(16, 16, 400);
                    text-decoration: underline;
                    text-underline-offset: 4px;
                    text-transform: uppercase;
                }
            }

            #{$field}__select-dropdown {
                position: relative;
                z-index: 1;
                background-color: #ffffff;
                box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1),
                    0px 2px 4px -2px rgba(16, 24, 40, 0.06);
                border-radius: 8px;
                padding: 8px 0;
                // width: 402px;
                margin-left: -3px;
                margin-top: 8px;
                max-height: 160px;
                overflow-x: auto;
                pointer-events: none;
                opacity: 0;
                transition: opacity 0.2s ease-in-out, color 0.2s ease-in-out,
                    background-color 0.2s ease-in-out;

                @include custom-scrollbars;

                ul {
                    display: flex;
                    flex-direction: column;

                    li.option {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: 38px;
                        padding: 0 16px;
                        width: 100%;

                        &:not(.--disabled):hover,
                        &:not(.--selected):hover {
                            background-color: #f7f7f7;
                        }

                        &.--selected {
                            background-color: $gray !important;
                            color: $white;
                        }

                        &.--disabled {
                            color: $gray-25;
                            pointer-events: none;
                            cursor: not-allowed;
                        }
                    }
                }
            }

            &:hover {
                #{$field}__select-display {
                    color: $gray-700;
                }
            }

            &.--open {
                z-index: 6;

                .caret {
                    transform: rotate(180deg);
                }

                #{$field}__select-dropdown {
                    opacity: 1;
                    pointer-events: all;
                }
            }

            &.--drop-up {
                #{$field}__select-dropdown {
                    position: absolute;
                    inset: auto 0 calc(100% + 10px) 0;
                }
            }
        }

        &.--select:first-child {
            z-index: 6;
        }

        &__toggle-visibility {
            position: absolute;
            z-index: 1;
            right: calc(1.1rem - 5px);
            top: 11px;
            border: 0;
            background: none;
            outline: none;
            padding: 4px;
            margin: 0 auto;
            cursor: pointer;

            .show {
                width: 24px;
                display: none;
            }

            .hide {
                width: 24px;
                display: block;
                margin-top: -3px;
            }

            &.--hide {
                .hide {
                    display: none;
                }

                .show {
                    display: block;
                }
            }
        }

        &__instruction {
            display: block;
            padding: 0 1rem;
            margin: 0;
            text-align: center;
            @include font-size(16, 30);
        }

        &__search {
            position: relative;

            .placeholder {
                position: absolute;
                inset: 0;
                width: 100%;
                padding: 0.8rem;
                @include font-size(20);
                color: $gray-25;
                pointer-events: none;

                svg {
                    display: inline-block;
                    margin-left: 5px;

                    * {
                        fill: $gray-25;
                    }
                }
            }

            input {
                text-align: center;
                width: 100%;
                padding: 0.8rem;
                border: 2px solid $gray-25;
                @include font-size(20);

                &::-webkit-input-placeholder {
                    color: transparent;
                }
                &::-moz-placeholder {
                    color: transparent;
                }
                &:-moz-placeholder {
                    color: transparent;
                }

                &:focus + .placeholder,
                &:not(:placeholder-shown) + .placeholder {
                    display: none;
                }
            }

            .empty {
                display: none;
                margin-bottom: 0;
                padding: 1rem;
                @include font-size(16);
                color: $gray-25;

                &.--show {
                    display: block;
                }
            }
        }
    }

    &__footer {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    @include device-sm {
        &__field {
            $field: &;

            &__input,
            &__textarea,
            &__select,
            &__radio-label,
            &__file {
                padding: 1.13rem 0.9rem 0.4rem 0.9rem;
                @include font-size(16);
            }

            &__textarea {
                padding-top: 0.8rem;
            }

            &.--textarea {
                textarea {
                    margin-top: 1.2rem;
                    @include font-size(18);
                }

                #{$field}__error {
                    min-height: 48px;
                }

                #{$field}__footer {
                    grid-template-columns: auto 80px;

                    #{$field}__length-counter {
                        @include font-size(10, 10);
                    }
                }
            }

            &[data-type="file"] {
                #{$field}__file {
                    padding: 0.765rem 0.9rem 0.765rem 0.9rem;
                }
            }

            &.--optional {
                &::after {
                    @include font-size(10, 10);
                    top: 1.25rem;
                    right: 1rem;
                }
            }

            // &__label {
            //     @include font-size(16);
            //     top: 12px;
            // }

            &__instruction {
                @include font-size(14, 20);
                margin-top: 8px;
            }
        }

        &__message {
            p {
                @include font-size(18, 32);
            }
        }

        .ego-form__field__select-dropdown {
            position: fixed !important;
            bottom: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            background: rgba(0,0,0,.5) !important;
            z-index: 10;
            margin: 0;
            max-height: none !important;

            ul {
                background-color: $white;
                position: fixed;
                bottom: 0;
                left: 0;
                padding: 13px 0px 62px 0px;
                border-radius: 16px 16px 0px 0px;
                z-index: 11;
                width: 100vw;
                text-transform: uppercase;
                overflow-y: scroll;
                @include custom-scrollbars;

                li {
                    padding-left: 24px !important;
                    padding-right: 24px !important;
                }
            }
        }
    }
}
