.btn-large {
    padding: 16px 32px;
}

.btn-small {
    padding: 12px 32px;
}

.btn-large,
.btn-small {
    border: none;
    background-color: transparent;
    outline: transparent;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    display: block;
    width: max-content;
    height: max-content;
    @include font-size(14, 16.8, 400);
    transition: all $timming ease-in-out;

    @include rwd(800) {
        width: 100%;
    }

    &:hover {
        cursor: pointer;
    }

    &.btn-primary-white {
        background-color: $white;
        color: $black;
        border: 2px solid $white;

        &:hover {
            background-color: $gray-700;
            color: $white;
            border: 2px solid $gray-700;
        }

        &:focus {
            background-color: $white;
            color: $black;
            border: 2px solid $blue-light;
        }

        &.--disabled,
        &:disabled {
            background-color: $gray-200;
            color: $gray-700;
            border: 2px solid $gray-200;
            pointer-events: none;
        }
    }

    &.btn-primary-black {
        background-color: $black;
        color: $white;
        border: 2px solid $black;

        &:hover {
            background-color: $gray-700;
            color: $white;
            border: 2px solid $gray-700;
        }

        &:focus {
            background-color: $black;
            color: $white;
            border: 2px solid $blue-light;
        }

        &.--disabled,
        &:disabled {
            background-color: $gray-200;
            color: $gray-700;
            border: 2px solid $gray-200;
            pointer-events: none;
        }
    }

    &.btn-outline-white {
        background-color: transparent;
        color: $white;
        border: 2px solid $white;

        &:hover {
            background-color: rgba(0, 0, 0, 0.2);
            color: $white;
            border: 2px solid $white;
        }

        &:focus {
            background-color: transparent;
            color: $white;
            border: 2px solid $blue-light;
        }

        &.--disabled,
        &:disabled {
            background-color: transparent;
            color: #bebec0;
            border: 2px solid $gray-200;
            pointer-events: none;
        }
    }

    &.btn-outline-black {
        background-color: transparent;
        color: $black;
        border: 2px solid $black;

        &:hover {
            background-color: rgba(0, 0, 0, 0.2);
            color: $black;
            border: 2px solid $black;
        }

        &:focus {
            background-color: transparent;
            color: $black;
            border: 2px solid $blue-light;
        }

        &.--disabled,
        &:disabled {
            background-color: transparent;
            color: #bebec0;
            border: 2px solid $gray-200;
            pointer-events: none;
        }
    }

    &.with-icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
    }
}

.text-link {
    cursor: pointer;
    background: none;
    border: 0;
    padding: 0;
    text-transform: uppercase;

    &.primary-link-white {
        @include font-size(12, 36, 400, 5);
        color: $white;
        text-decoration: underline;

        &.--disabled,
        &:disabled {
            color: $gray-600;
            pointer-events: none;
        }
    }

    &.primary-link-black-and-white {
        @include font-size(12, 36, 400, 5);
        color: $black;
        text-decoration: underline;

        @include rwd-min(1200) {
            color: $white;
        }

        &.--disabled,
        &:disabled {
            color: $gray-600;
            pointer-events: none;
        }
    }

    &.primary-link-black {
        @include font-size(12, 36, 400, 5);
        color: $black;
        text-decoration: underline;

        &.--disabled,
        &:disabled {
            color: $gray-600;
            pointer-events: none;
        }
    }

    &.link-dropdown {
        text-decoration: none;

        display: flex;

        gap: 0.85rem;
    }

    &.with-icon {
        display: inline-flex;
        align-items: center;
        gap: 10px;

        svg * {
            fill: currentColor;
        }
    }
}

.btn-card {
    padding: 0.75rem 1.5rem;

    background-color: $white;

    border: 1px $gray-200 solid;

    display: flex;

    flex-direction: row;

    justify-content: space-between;

    gap: 1rem;

    p {
        text-align: left;
    }

    .left,
    .right {
        display: flex;

        flex-direction: column;

        // justify-content: flex-start;
        align-items: flex-start;
    }

    .right {
        flex: 0 1 60%;
    }

    .salesman {
        @include font-size(16, 24, 300);
    }

    .left {
        p {
            color: $gray-700;
        }
    }

    .right {
        p {
            @include font-size(12, 18, 300);
        }
    }

    .name {
        @include font-size(16, 24, 400);
    }

    &:hover {
        background-color: $gray-25;
    }

    &:focus {
        border: 2px $blue-light solid;
    }

    &.--selected {
        border: 2px $black solid;
    }

    &.--disabled {
        background-color: $gray-200;

        pointer-events: none;

        cursor: not-allowed;
    }

    &__container {
        display: flex;

        flex-direction: column;

        gap: 1rem;
    }
}

.btn-cart {
    width: 100%;

    &__container {
        display: flex;

        justify-content: space-between;

        align-items: center;

        gap: 0.75rem;

        margin: 0 auto;
    }

    &__with-total {
        .btn-cart__container {
            margin: 0;

            width: 100%;
        }

        .btn-cart__label-container {
            width: 100%;

            display: flex;

            flex-direction: column;

            @include device-lg("min") {
                flex-direction: row;

                justify-content: space-between;
            }
        }

        .btn-cart__label,
        .btn-cart__total {
            @include font-size(14, 18, 400);

            text-align: start;

            margin: 0;
        }

        .btn-cart__total {
            font-weight: 700;
        }

        &.--1-line {
            .btn-cart__label-container {
                width: 100%;

                display: flex;
                flex-direction: row;
                justify-content: space-between;
            }
        }
    }

    &.--btn-full-width {
        width: 100%;

        display: flex;
        flex-direction: row;
        justify-content: center;
    }
}
