.spinner-container {
    position: fixed;
    top: 0;
    box-sizing: border-box;
    z-index: 10000;
    width: 100vw;
    height: 100vh;
    background: $white;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spinner {
	width: 80px;
    height: 80px;
	clear: both;
    margin: 20px auto;
    border: 8px rgba($black,0.15) solid;
	border-top: 8px rgba($black,1) solid;
	border-radius: 50%;
	animation: spinner-rotation .6s infinite linear;
}

@keyframes spinner-rotation {
	from { transform: rotate(0deg); }
	to { transform: rotate(359deg); }
}