.footer {
    background-color: $gray-800;
    color: $white;
    padding-top: 64px;
    padding-bottom: 64px;
    position: relative;

    // This is to make the footer stick to the bottom of the page.
    // margin-top: auto;
    // margin-bottom: 0;

    &-grid {
        @include main-grid();

        &__contact_corporative {
            grid-row: 1;
            grid-column: 1 / -1;
            margin-bottom: 120px;
            @include main-grid();

            h2 {
                align-self: start;
                grid-column: 1 / span 12;
            }

            h2.mobile {
                display: none;
            }

            div {
                grid-column: 14 / -1;

                p {
                    margin-bottom: 40px;
                }
            }
        }

        &__social_corporative {
            grid-row: 2;
            grid-column: 1 / -1;
            @include main-grid();
            margin-bottom: 72px;

            &-list {
                grid-column: 1 / span 12;
                display: flex;
                gap: 40px;

                p {
                    margin-bottom: 12px;
                }

                li {
                    margin-bottom: 12px;
                    @include font-size(16, 24, 400, 0);
                }
            }

            &-buttons {
                grid-column: 14 / -1;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                align-self: start;

                p {
                    margin-bottom: 16px;
                }

                a {
                    margin-left: 16px;
                    text-decoration: none;
                    color: inherit;
                    width: 40px;
                    height: 40px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    background-color: white;
                    border-radius: 50%;
                    transition: all $timming ease-in-out;

                    img {
                        height: min-content;
                    }

                    &:hover {
                        background-color: $black;

                        img {
                            filter: invert(1);
                        }
                    }
                }
            }
        }

        &__terms_corporative {
            grid-row: 3;
            grid-column: 1 / -1;
            border-top: 1px solid $gray;
            padding-top: 32px;
            @include main-grid();

            &-top {
                grid-row: 1;
                grid-column: 1 / -1;
                display: flex;
                justify-content: space-between;
                margin-bottom: 16px;

                .copyright {
                    color: $gray-400;
                    font-weight: 300;
                }

                .links {
                    display: flex;
                    @include font-size(16, 24, 300);

                    li {
                        margin-left: 12px;
                        text-decoration: underline;
                    }
                }

                ul {
                    flex-wrap: wrap;
                }
            }

            &-bottom {
                grid-row: 2;
                grid-column: 1 / -1;
                p {
                    font-weight: 300;
                    color: $gray-400;
                }
            }
        }

        &__contact_store {
            grid-row: 1;
            grid-column: 1 / -1;
            margin-bottom: 78px;
            display: flex;
            flex-direction: column;
            @include main-grid();

            span {
                grid-column: 1 / span 10;
            }

            .footer-grid__social-buttons {
                grid-column: 14 / -1;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                align-self: start;

                p {
                    margin-bottom: 16px;
                }

                a {
                    margin-left: 16px;
                    text-decoration: none;
                    color: inherit;
                    width: 40px;
                    height: 40px;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    background-color: white;
                    border-radius: 50%;

                    img {
                        height: min-content;
                    }

                    &:hover {
                        background-color: $black;

                        img {
                            filter: invert(1);
                        }
                    }
                }
            }

            p {
                font-weight: 300;
            }
        }

        &__social_store {
            grid-row: 1;
            grid-column: 14 / -1;
            margin-bottom: 78px;

            display: flex;
            flex-direction: column;
            align-items: flex-end;
            align-self: start;

            p {
                margin-bottom: 16px;
            }

            a {
                margin-left: 16px;
                text-decoration: none;
                color: inherit;
                width: 40px;
                height: 40px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                background-color: white;
                border-radius: 50%;

                img {
                    height: min-content;
                }

                &:hover {
                    background-color: $black;

                    img {
                        filter: invert(1);
                    }
                }
            }
        }

        &__terms_store {
            grid-row: 3;
            grid-column: 1 / -1;
            border-top: 1px solid $gray;
            padding-top: 32px;
            display: flex;
            justify-content: space-between;
            margin-bottom: 16px;

            .copyright {
                color: $gray-400;
                font-weight: 300;
            }

            .links {
                display: flex;
                @include font-size(16, 24, 300);

                li {
                    margin-left: 12px;
                    text-decoration: underline;
                }
            }

            ul {
                flex-wrap: wrap;
            }
        }

        @include device-lg {
            &__contact_corporative {
                margin-bottom: 40px;

                h2 {
                    grid-column: 1 / -1;
                    grid-row: 1;
                    margin-bottom: 16px;
                }

                h2.mobile {
                    display: block;
                }

                h2.desktop {
                    display: none;
                }

                div {
                    grid-column: 1 / -1;
                    grid-row: 2;

                    p {
                        margin-bottom: 16px;
                    }
                }
            }

            &__social_corporative {
                grid-row: 2;
                grid-column: 1 / -1;
                @include main-grid();
                margin-bottom: 72px;

                &-list {
                    grid-column: 1 / span 12;
                    grid-row: 1;
                }

                &-buttons {
                    grid-column: 14 / -1;
                    grid-row: 1;
                }
            }

            &__terms_corporative {
                &-top {
                    flex-direction: column-reverse;

                    .links {
                        margin-bottom: 24px;

                        li {
                            margin-right: 12px;
                            margin-left: 0;
                        }
                    }
                }
            }
        }

        @include rwd(915) {
            &__social_corporative {
                margin-bottom: 40px;

                &-list {
                    grid-column: 1 / -1;
                    grid-row: 1;
                }

                &-buttons {
                    grid-column: 1 / -1;
                    grid-row: 2;
                    align-items: flex-start;
                    margin-top: 20px;

                    a {
                        margin-left: 0;
                        margin-right: 16px;
                    }
                }
            }
        }

        @include device-md {
            &__contact_corporative {
                h2 {
                    @include font-size(26, 34, 400, -2);
                }
            }

            &__social_corporative {
                &-list {
                    flex-direction: column;
                    gap: 24px;
                }
            }

            &__contact_store {
                flex-direction: column;
                margin-bottom: 40px;

                .footer-grid__social-buttons {
                    grid-column: 1 / -1;
                    grid-row: 2;
                    align-items: flex-start;
                    margin-top: 20px;

                    a {
                        margin-left: 0;
                        margin-right: 16px;
                    }
                }
            }

            &__terms_store {
                flex-direction: column-reverse;

                .links {
                    margin-bottom: 24px;

                    li {
                        margin-right: 12px;
                        margin-left: 0;
                    }
                }
            }
        }
    }

    @include device-md {
        padding-top: 40px;
        padding-bottom: 40px;
    }
}
