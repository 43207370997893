/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: transparent;
}

@font-face {
  font-family: "Nobel";
  src: url("../fonts/nobel-black-webfont.woff2") format("woff2"), url("../fonts/nobel-black-webfont.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Nobel";
  src: url("../fonts/nobel-blackitalic-webfont.woff2") format("woff2"), url("../fonts/nobel-blackitalic-webfont.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: "Nobel";
  src: url("../fonts/nobel-bold-webfont.woff2") format("woff2"), url("../fonts/nobel-bold-webfont.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Nobel";
  src: url("../fonts/nobel-bolditalic-webfont.woff2") format("woff2"), url("../fonts/nobel-bolditalic-webfont.woff") format("woff");
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: "Nobel";
  src: url("../fonts/nobel-regular-webfont.woff2") format("woff2"), url("../fonts/nobel-regular-webfont.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Nobel";
  src: url("../fonts/nobel-regularitalic-webfont.woff2") format("woff2"), url("../fonts/nobel-regularitalic-webfont.woff") format("woff");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Nobel";
  src: url("../fonts/nobel-book-webfont.woff2") format("woff2"), url("../fonts/nobel-book-webfont.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Nobel";
  src: url("../fonts/nobel-bookitalic-webfont.woff2") format("woff2"), url("../fonts/nobel-bookitalic-webfont.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Nobel";
  src: url("../fonts/nobel-light-webfont.woff2") format("woff2"), url("../fonts/nobel-light-webfont.woff") format("woff");
  font-weight: 200;
  font-style: normal;
}
@font-face {
  font-family: "Nobel";
  src: url("../fonts/nobel-lightitalic-webfont.woff2") format("woff2"), url("../fonts/nobel-lightitalic-webfont.woff") format("woff");
  font-weight: 200;
  font-style: italic;
}
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  width: 100%;
  scroll-behavior: smooth;
  background-color: #f4f4f4;
}
body.no-scroll,
html.no-scroll {
  overflow: hidden;
}
body.block,
html.block {
  pointer-events: none;
}

body.dark,
body.background-white {
  background-color: #FFFFFF;
}

body.background-black {
  background-color: #000000;
  color: #FFFFFF;
}

body.background-gray {
  background-color: #F7F7F7;
}

body,
button {
  font-family: "Nobel", Arial, Helvetica, sans-serif;
  color: #000000;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.3333333333;
  letter-spacing: normal;
}

.wrapper {
  display: block;
  width: 100%;
  position: relative;
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 80px;
  padding-right: 80px;
}
@media (max-width: 1023px) {
  .wrapper {
    padding-left: 32px;
    padding-right: 32px;
  }
}
@media (max-width: 719px) {
  .wrapper {
    padding-left: 24px;
    padding-right: 24px;
  }
}

section:first-of-type {
  padding-top: 0;
}
section.page-top-navigation {
  margin-top: 44px;
  padding-bottom: 24px;
}
section.page-top-navigation + section {
  padding-top: 0;
}

strong {
  font-weight: 700;
}

a {
  text-decoration: none;
  color: inherit;
}

.--with-margin-top {
  margin-top: 67px !important;
}
@media (min-width: 1024px) {
  .--with-margin-top {
    margin-top: 75px !important;
  }
}

.btn-large {
  padding: 16px 32px;
}

.btn-small {
  padding: 12px 32px;
}

.btn-large,
.btn-small {
  border: none;
  background-color: transparent;
  outline: transparent;
  text-decoration: none;
  text-transform: uppercase;
  text-align: center;
  display: block;
  width: max-content;
  height: max-content;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: normal;
  transition: all 0.2s ease-in-out;
}
@media (max-width: 800px) {
  .btn-large,
  .btn-small {
    width: 100%;
  }
}
.btn-large:hover,
.btn-small:hover {
  cursor: pointer;
}
.btn-large.btn-primary-white,
.btn-small.btn-primary-white {
  background-color: #FFFFFF;
  color: #000000;
  border: 2px solid #FFFFFF;
}
.btn-large.btn-primary-white:hover,
.btn-small.btn-primary-white:hover {
  background-color: #454545;
  color: #FFFFFF;
  border: 2px solid #454545;
}
.btn-large.btn-primary-white:focus,
.btn-small.btn-primary-white:focus {
  background-color: #FFFFFF;
  color: #000000;
  border: 2px solid #73B8DF;
}
.btn-large.btn-primary-white.--disabled, .btn-large.btn-primary-white:disabled,
.btn-small.btn-primary-white.--disabled,
.btn-small.btn-primary-white:disabled {
  background-color: #E8E8E8;
  color: #454545;
  border: 2px solid #E8E8E8;
  pointer-events: none;
}
.btn-large.btn-primary-black,
.btn-small.btn-primary-black {
  background-color: #000000;
  color: #FFFFFF;
  border: 2px solid #000000;
}
.btn-large.btn-primary-black:hover,
.btn-small.btn-primary-black:hover {
  background-color: #454545;
  color: #FFFFFF;
  border: 2px solid #454545;
}
.btn-large.btn-primary-black:focus,
.btn-small.btn-primary-black:focus {
  background-color: #000000;
  color: #FFFFFF;
  border: 2px solid #73B8DF;
}
.btn-large.btn-primary-black.--disabled, .btn-large.btn-primary-black:disabled,
.btn-small.btn-primary-black.--disabled,
.btn-small.btn-primary-black:disabled {
  background-color: #E8E8E8;
  color: #454545;
  border: 2px solid #E8E8E8;
  pointer-events: none;
}
.btn-large.btn-outline-white,
.btn-small.btn-outline-white {
  background-color: transparent;
  color: #FFFFFF;
  border: 2px solid #FFFFFF;
}
.btn-large.btn-outline-white:hover,
.btn-small.btn-outline-white:hover {
  background-color: rgba(0, 0, 0, 0.2);
  color: #FFFFFF;
  border: 2px solid #FFFFFF;
}
.btn-large.btn-outline-white:focus,
.btn-small.btn-outline-white:focus {
  background-color: transparent;
  color: #FFFFFF;
  border: 2px solid #73B8DF;
}
.btn-large.btn-outline-white.--disabled, .btn-large.btn-outline-white:disabled,
.btn-small.btn-outline-white.--disabled,
.btn-small.btn-outline-white:disabled {
  background-color: transparent;
  color: #bebec0;
  border: 2px solid #E8E8E8;
  pointer-events: none;
}
.btn-large.btn-outline-black,
.btn-small.btn-outline-black {
  background-color: transparent;
  color: #000000;
  border: 2px solid #000000;
}
.btn-large.btn-outline-black:hover,
.btn-small.btn-outline-black:hover {
  background-color: rgba(0, 0, 0, 0.2);
  color: #000000;
  border: 2px solid #000000;
}
.btn-large.btn-outline-black:focus,
.btn-small.btn-outline-black:focus {
  background-color: transparent;
  color: #000000;
  border: 2px solid #73B8DF;
}
.btn-large.btn-outline-black.--disabled, .btn-large.btn-outline-black:disabled,
.btn-small.btn-outline-black.--disabled,
.btn-small.btn-outline-black:disabled {
  background-color: transparent;
  color: #bebec0;
  border: 2px solid #E8E8E8;
  pointer-events: none;
}
.btn-large.with-icon,
.btn-small.with-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.text-link {
  cursor: pointer;
  background: none;
  border: 0;
  padding: 0;
  text-transform: uppercase;
}
.text-link.primary-link-white {
  font-size: 12px;
  font-weight: 400;
  line-height: 3;
  letter-spacing: 0.05em;
  color: #FFFFFF;
  text-decoration: underline;
}
.text-link.primary-link-white.--disabled, .text-link.primary-link-white:disabled {
  color: #A7A7A8;
  pointer-events: none;
}
.text-link.primary-link-black-and-white {
  font-size: 12px;
  font-weight: 400;
  line-height: 3;
  letter-spacing: 0.05em;
  color: #000000;
  text-decoration: underline;
}
@media (min-width: 1200px) {
  .text-link.primary-link-black-and-white {
    color: #FFFFFF;
  }
}
.text-link.primary-link-black-and-white.--disabled, .text-link.primary-link-black-and-white:disabled {
  color: #A7A7A8;
  pointer-events: none;
}
.text-link.primary-link-black {
  font-size: 12px;
  font-weight: 400;
  line-height: 3;
  letter-spacing: 0.05em;
  color: #000000;
  text-decoration: underline;
}
.text-link.primary-link-black.--disabled, .text-link.primary-link-black:disabled {
  color: #A7A7A8;
  pointer-events: none;
}
.text-link.link-dropdown {
  text-decoration: none;
  display: flex;
  gap: 0.85rem;
}
.text-link.with-icon {
  display: inline-flex;
  align-items: center;
  gap: 10px;
}
.text-link.with-icon svg * {
  fill: currentColor;
}

.btn-card {
  padding: 0.75rem 1.5rem;
  background-color: #FFFFFF;
  border: 1px #E8E8E8 solid;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
}
.btn-card p {
  text-align: left;
}
.btn-card .left,
.btn-card .right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.btn-card .right {
  flex: 0 1 60%;
}
.btn-card .salesman {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: normal;
}
.btn-card .left p {
  color: #454545;
}
.btn-card .right p {
  font-size: 12px;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: normal;
}
.btn-card .name {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: normal;
}
.btn-card:hover {
  background-color: #F7F7F7;
}
.btn-card:focus {
  border: 2px #73B8DF solid;
}
.btn-card.--selected {
  border: 2px #000000 solid;
}
.btn-card.--disabled {
  background-color: #E8E8E8;
  pointer-events: none;
  cursor: not-allowed;
}
.btn-card__container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.btn-cart {
  width: 100%;
}
.btn-cart__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.75rem;
  margin: 0 auto;
}
.btn-cart__with-total .btn-cart__container {
  margin: 0;
  width: 100%;
}
.btn-cart__with-total .btn-cart__label-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media (min-width: 1024px) {
  .btn-cart__with-total .btn-cart__label-container {
    flex-direction: row;
    justify-content: space-between;
  }
}
.btn-cart__with-total .btn-cart__label,
.btn-cart__with-total .btn-cart__total {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2857142857;
  letter-spacing: normal;
  text-align: start;
  margin: 0;
}
.btn-cart__with-total .btn-cart__total {
  font-weight: 700;
}
.btn-cart__with-total.--1-line .btn-cart__label-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.btn-cart.--btn-full-width {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.ego-form {
  width: 100%;
  margin: 0 auto;
}
.ego-form__field {
  width: 100%;
  position: relative;
  margin-bottom: 40px;
  background-color: transparent;
  z-index: 4;
}
.ego-form__field.--border {
  border: 1px #A7A7A8 solid !important;
}
.ego-form__field__dropdown .value-placeholder {
  text-transform: none !important;
}
.ego-form__field__select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  padding-right: 45px;
}
.ego-form__field__select::-ms-expand {
  display: none;
}
.ego-form__field__input, .ego-form__field__select, .ego-form__field__radio-label, .ego-form__field__file {
  display: block;
  position: relative;
  z-index: 0;
  border: 1px solid #E8E8E8;
  background-color: #FFFFFF;
  padding: 22px 16px 8px 16px;
  height: 48px;
  width: 100%;
  color: #000000;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4285714286;
  letter-spacing: 0em;
  font-family: "Nobel", Arial, Helvetica, sans-serif;
  transition: all 0.2s ease-in-out;
  background-repeat: no-repeat;
  background-position: center right 16px;
}
.ego-form__field__input:hover, .ego-form__field__select:hover, .ego-form__field__radio-label:hover, .ego-form__field__file:hover {
  border-color: #A7A7A8;
}
.ego-form__field__input:focus, .ego-form__field__select:focus, .ego-form__field__radio-label:focus, .ego-form__field__file:focus {
  border-color: #A7A7A8;
  outline: none;
  outline-color: #000000;
}
.ego-form__field__input:focus + label, .ego-form__field__select:focus + label, .ego-form__field__radio-label:focus + label, .ego-form__field__file:focus + label {
  position: absolute;
  top: 7px;
  font-size: 12px;
  font-weight: 300;
  line-height: 1;
  letter-spacing: 0em;
}
.ego-form__field__input::placeholder, .ego-form__field__select::placeholder, .ego-form__field__radio-label::placeholder, .ego-form__field__file::placeholder {
  color: #454545;
}
.ego-form__field__label {
  pointer-events: none;
  position: absolute;
  top: 15px;
  left: 0px;
  padding-left: 17px;
  font-size: 12px;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0em;
  color: #454545;
  user-select: none;
  cursor: text;
  width: 100%;
  text-align: left;
  transition: all 0.2s ease-in-out;
  position: absolute;
  top: 7px;
  font-size: 12px;
  font-weight: 300;
  line-height: 1;
  letter-spacing: 0em;
}
.ego-form__field__textarea {
  padding-top: 1.4rem;
  height: 100%;
  resize: none;
}
.ego-form__field__textarea::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4285714286;
  letter-spacing: normal;
  color: #454545;
}
.ego-form__field__textarea::-moz-placeholder {
  /* Firefox 19+ */
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4285714286;
  letter-spacing: normal;
  color: #454545;
}
.ego-form__field__textarea:-moz-placeholder {
  /* Firefox 18- */
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4285714286;
  letter-spacing: normal;
  color: #454545;
}
.ego-form__field.--checkbox {
  cursor: pointer;
}
.ego-form__field.--checkbox .ego-form__field__label {
  pointer-events: all;
  position: static;
  padding: 0;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: normal;
}
.ego-form__field.--checkbox .ego-form__field__checkbox {
  appearance: none;
  background-color: #FFFFFF;
  margin: 0 6px 0 0;
  font: inherit;
  color: currentColor;
  width: 1.5em;
  height: 1.5em;
  border: 2px solid #a7a7a8;
  border-radius: 0.4em;
  transform: translateY(-0.075em);
  display: inline-grid;
  place-content: center;
  vertical-align: middle;
  transition: all 0.2s ease-in-out;
}
.ego-form__field.--checkbox .ego-form__field__checkbox::before {
  content: "";
  width: 0.8em;
  height: 0.8em;
  transform: scale(0);
  box-shadow: inset 1em 1em #FFFFFF;
  background-color: CanvasText;
  clip-path: polygon(18% 41%, 5% 55%, 37% 84%, 100% 20%, 87% 7%, 37% 58%);
  transition: all 0.2s ease-in-out;
}
.ego-form__field.--checkbox .ego-form__field__checkbox:hover {
  border-color: #8A8D8F;
  background-color: #E8E8E8;
}
.ego-form__field.--checkbox .ego-form__field__checkbox:hover::before {
  box-shadow: inset 1em 1em #E8E8E8;
}
.ego-form__field.--checkbox .ego-form__field__checkbox:focus {
  box-shadow: 0px 0px 0px 4px rgba(76, 13, 224, 0.2);
  outline: none;
}
.ego-form__field.--checkbox .ego-form__field__checkbox:checked {
  background-color: #8A8D8F;
  border-color: #8A8D8F;
}
.ego-form__field.--checkbox .ego-form__field__checkbox:checked::before {
  transform: scale(1);
}
.ego-form__field.--checkbox.--has-error .ego-form__field__checkbox {
  border-color: #AD0B00 !important;
}
.ego-form__field.--checkbox.--has-error .ego-form__field__checkbox:checked {
  background-color: #AD0B00;
}
.ego-form__field.--checkbox .ego-form__field__error {
  position: static;
  margin: auto;
  display: table;
  margin-top: 10px;
}
.ego-form__field.--radio {
  cursor: pointer;
}
.ego-form__field.--radio .ego-form__field__label {
  display: block;
  pointer-events: all;
  position: static;
  padding: 0;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: normal;
}
.ego-form__field.--radio .ego-form__field__label:not(:last-child) {
  margin-bottom: 32px;
}
.ego-form__field.--radio .ego-form__field__radio {
  appearance: none;
  background-color: #FFFFFF;
  margin: 0 6px 0 0;
  font: inherit;
  color: currentColor;
  width: 1.5em;
  height: 1.5em;
  border: 2px solid #a7a7a8;
  border-radius: 50%;
  transform: translateY(-0.075em);
  display: inline-grid;
  place-content: center;
  vertical-align: middle;
  transition: all 0.2s ease-in-out;
}
.ego-form__field.--radio .ego-form__field__radio::before {
  content: "";
  width: 0.6em;
  height: 0.6em;
  border-radius: 50%;
  transform: scale(0);
  background-color: #FFFFFF;
  transition: transform 0.2s ease-in-out;
}
.ego-form__field.--radio .ego-form__field__radio:hover {
  border-color: #8A8D8F;
  background-color: #E8E8E8;
}
.ego-form__field.--radio .ego-form__field__radio:hover::before {
  box-shadow: inset 1em 1em #E8E8E8;
}
.ego-form__field.--radio .ego-form__field__radio:focus {
  box-shadow: 0px 0px 0px 4px rgba(76, 13, 224, 0.2);
  outline: none;
}
.ego-form__field.--radio .ego-form__field__radio:checked {
  background-color: #8A8D8F;
  border-color: #8A8D8F;
}
.ego-form__field.--radio .ego-form__field__radio:checked::before {
  transform: scale(1);
}
.ego-form__field.--radio.--inline-radio {
  display: flex;
  flex-flow: row wrap;
  row-gap: 7px;
  column-gap: 3px;
  margin-bottom: 15px;
}
.ego-form__field.--radio.--inline-radio .ego-form__field__label {
  display: inline-block;
  width: auto;
  margin: 4px 0;
}
.ego-form__field.--radio.--inline-radio .ego-form__field__radio::before {
  display: none;
}
.ego-form__field.--radio.--inline-radio .ego-form__field__radio:checked {
  border-color: #FFFFFF;
  box-shadow: 0px 0px 0px 4px #8A8D8F;
}
.ego-form__field.--radio.--has-error .ego-form__field__checkbox {
  border-color: #AD0B00 !important;
}
.ego-form__field.--radio.--has-error .ego-form__field__checkbox:checked {
  background-color: #AD0B00;
}
.ego-form__field.--radio .ego-form__field__error {
  position: static;
  margin: auto;
  display: table;
  margin-top: 10px;
}
.ego-form__field.--password input {
  padding-right: 52px;
}
.ego-form__field.--file .ego-form__field__file {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  padding: 1.3rem 1rem 1.3rem 1rem;
  cursor: pointer;
}
.ego-form__field.--file .ego-form__field__file .attachment-control {
  display: flex;
  align-items: center;
  min-height: 22px;
}
.ego-form__field.--file .ego-form__field__file .attachment-file-info {
  display: grid;
  grid-template-columns: auto minmax(60px, 1fr);
  align-items: center;
  gap: 10px;
}
.ego-form__field.--file .ego-form__field__file .attachment-file-info .attachment-file-name {
  font-size: 16px;
  font-weight: 300;
  line-height: 1;
  letter-spacing: normal;
  text-decoration: underline;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.ego-form__field.--file .ego-form__field__file .attachment-file-info .attachment-file-size {
  font-size: 12px;
  font-weight: 300;
  line-height: 1;
  letter-spacing: normal;
  color: #F7F7F7;
  min-width: 60px;
}
.ego-form__field.--file .ego-form__field__file .attachment-file-info .attachment-file-size i {
  color: #8A8D8F;
  text-transform: uppercase;
}
.ego-form__field.--file .ego-form__field__file .attachment-file-info .attachment-file-size:empty:before {
  content: attr(data-placeholder);
}
.ego-form__field.--file .ego-form__field__file .icon__clip {
  height: 1rem;
}
.ego-form__field.--file .ego-form__field__file .attachment-remove {
  display: none;
  cursor: pointer;
  border: 0;
  background: none;
  outline: none;
  padding: 0;
  margin: 0 0 0 auto;
  font-size: 23px;
  line-height: 0.8;
}
.ego-form__field.--file.--has-file .ego-form__field__file {
  border-color: #A7A7A8;
  outline: none;
  outline-color: #000000;
}
.ego-form__field.--file.--has-file .ego-form__field__file .icon__clip {
  display: none;
}
.ego-form__field.--file.--has-file .ego-form__field__file .attachment-remove {
  display: block;
}
.ego-form__field.--file input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.ego-form__field.--disabled {
  pointer-events: none;
  opacity: 0.4;
}
.ego-form__field__error {
  opacity: 0;
  color: #AD0B00;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.3333333333;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 8px;
  display: flex;
  align-items: self-end;
  background-repeat: no-repeat;
  padding-left: 20px;
  transition: opacity 0.2s ease-in-out;
}
.ego-form__field__error img {
  margin-right: 9px;
}
.ego-form__field__error strong {
  font-weight: 400;
}
.ego-form__field__error.--active {
  opacity: 1;
}
.ego-form__field__warning {
  color: #8A8D8F;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.3333333333;
  letter-spacing: 0em;
  text-align: left;
  position: absolute;
  left: 0;
  top: 55px;
  display: flex;
  background-repeat: no-repeat;
  padding-left: 20px;
  height: 16px;
  transition: opacity 0.2s ease-in-out;
}
.ego-form__field__warning img {
  margin-right: 9px;
}
.ego-form__field__warning strong {
  font-weight: 400;
}
.ego-form__field.--filled .ego-form__field__input:not(:focus),
.ego-form__field.--filled .ego-form__field__select:not(:focus), .ego-form__field.--filled.--textarea:not(:focus), .ego-form__field.--filled-react .ego-form__field__input:not(:focus),
.ego-form__field.--filled-react .ego-form__field__select:not(:focus), .ego-form__field.--filled-react.--textarea:not(:focus) {
  border-color: #8A8D8F;
}
.ego-form__field.--filled .ego-form__field__label, .ego-form__field.--filled-react .ego-form__field__label {
  position: absolute;
  top: 7px;
  font-size: 12px;
  font-weight: 300;
  line-height: 1;
  letter-spacing: 0em;
}
.ego-form__field.--optional::after, .ego-form__field.--custom-assistive::after {
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: normal;
  content: "OPTIONAL";
  position: absolute;
  right: 1rem;
  top: 1.1rem;
  color: #000000;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
}
@media (max-width: 719px) {
  .ego-form__field.--optional::after, .ego-form__field.--custom-assistive::after {
    font-size: 10px;
    font-weight: 400;
    line-height: 1;
    letter-spacing: normal;
    right: 1rem;
    top: 1.3rem;
  }
}
.ego-form__field.--optional.--filled::after, .ego-form__field.--optional.--filled-react::after, .ego-form__field.--custom-assistive.--filled::after, .ego-form__field.--custom-assistive.--filled-react::after {
  opacity: 0;
}
.ego-form__field.--optional::after {
  content: "OPTIONAL";
}
.ego-form__field.--custom-assistive::after {
  content: attr(data-assistive);
}
.ego-form__field.--has-error .ego-form__field__input,
.ego-form__field.--has-error .ego-form__field__select,
.ego-form__field.--has-error .ego-form__field__file, .ego-form__field.--has-error.--textarea {
  background-color: #FFFFFF;
  border-color: #AD0B00 !important;
}
.ego-form__field.--has-error .ego-form__field__error {
  opacity: 1;
}
.ego-form__field.--has-error .ego-form__field__warning {
  opacity: 0;
}
.ego-form__field.--has-error.--optional:after {
  display: none !important;
}
.ego-form__field.--full-height {
  height: 100%;
}
.ego-form__field.--textarea {
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #E8E8E8;
  background-color: #FFFFFF;
  transition: all 0.2s ease-in-out;
  position: relative !important;
}
.ego-form__field.--textarea:hover {
  border-color: #000000;
}
.ego-form__field.--textarea:focus-within {
  border-color: #A7A7A8;
  outline: none;
  outline-color: #000000;
  outline-color: #000000;
}
.ego-form__field.--textarea textarea {
  display: block;
  font-family: "Nobel", Arial, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
  letter-spacing: normal;
  padding: 0;
  margin: 1rem;
  margin-top: 1.2rem;
  border: 0;
  background: transparent;
  outline: none !important;
}
.ego-form__field.--textarea textarea::-webkit-scrollbar {
  width: 0.5em;
  padding-left: 10px;
}
.ego-form__field.--textarea textarea.--horizontal-scroll::-webkit-scrollbar {
  height: 0.5em;
}
.ego-form__field.--textarea textarea::-webkit-scrollbar-track {
  box-shadow: none;
  -webkit-box-shadow: none;
}
.ego-form__field.--textarea textarea::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  outline: 0;
  border-radius: 5px;
}
.ego-form__field.--textarea.--big {
  min-height: 280px;
}
.ego-form__field.--textarea .ego-form__field__error {
  inset: 1px 1px auto auto;
  height: auto;
  width: fit-content;
  background-color: none;
  max-width: none;
  min-height: 70px;
  max-height: 50%;
}
.ego-form__field.--select, .ego-form__field.--select-react {
  display: block;
  position: relative;
  z-index: 0;
  height: 48px;
  width: 100%;
  color: #000000;
  font-size: 12px;
  font-weight: 400;
  line-height: 2;
  letter-spacing: 0.075em;
  font-family: "Nobel", Arial, Helvetica, sans-serif;
  transition: all 0.2s ease-in-out;
  cursor: default;
  border: none;
  z-index: 5;
}
.ego-form__field.--select .ego-form__field__label, .ego-form__field.--select-react .ego-form__field__label {
  top: 13px;
}
.ego-form__field.--select:hover, .ego-form__field.--select-react:hover {
  border-color: #F7F7F7;
}
.ego-form__field.--select:focus, .ego-form__field.--select.--open, .ego-form__field.--select-react:focus, .ego-form__field.--select-react.--open {
  border-color: #A7A7A8;
  outline: none;
  outline-color: #000000;
  z-index: 6;
}
.ego-form__field.--select:focus + label, .ego-form__field.--select.--open + label, .ego-form__field.--select-react:focus + label, .ego-form__field.--select-react.--open + label {
  position: absolute;
  top: 7px;
  font-size: 12px;
  font-weight: 300;
  line-height: 1;
  letter-spacing: 0em;
}
.ego-form__field.--select.--filled, .ego-form__field.--select.--filled-react, .ego-form__field.--select-react.--filled, .ego-form__field.--select-react.--filled-react {
  border-color: #E8E8E8;
}
.ego-form__field.--select.--filled .ego-form__field__label, .ego-form__field.--select.--filled-react .ego-form__field__label, .ego-form__field.--select-react.--filled .ego-form__field__label, .ego-form__field.--select-react.--filled-react .ego-form__field__label {
  top: 5px;
}
.ego-form__field.--select.--has-error, .ego-form__field.--select-react.--has-error {
  border-color: #AD0B00 !important;
}
.ego-form__field.--select .caret, .ego-form__field.--select-react .caret {
  content: "";
  display: inline-block;
  width: 22px;
  height: 22px;
  background: url("../images/dropdown-arrow.svg") no-repeat center center;
  background-size: contain;
}
.ego-form__field.--select.--open .caret, .ego-form__field.--select-react.--open .caret {
  transform: rotate(180deg);
}
.ego-form__field.--select .ego-form__field__select-display, .ego-form__field.--select-react .ego-form__field__select-display {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px;
  transition: all 0.2s ease-in-out;
  color: #000000;
}
.ego-form__field.--select .ego-form__field__select-display .value-placeholder, .ego-form__field.--select-react .ego-form__field__select-display .value-placeholder {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4285714286;
  letter-spacing: 0em;
  margin-top: 10px;
}
.ego-form__field.--select .ego-form__field__select-dropdown, .ego-form__field.--select-react .ego-form__field__select-dropdown {
  position: relative;
  z-index: 1;
  background-color: #FFFFFF;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  border: 1px solid #F7F7F7;
  padding: 8px 0;
  margin-left: -3px;
  margin-top: 8px;
  max-height: 160px;
  overflow-x: auto;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-in-out, color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}
.ego-form__field.--select .ego-form__field__select-dropdown::-webkit-scrollbar, .ego-form__field.--select-react .ego-form__field__select-dropdown::-webkit-scrollbar {
  width: 0.5em;
  padding-left: 10px;
}
.ego-form__field.--select .ego-form__field__select-dropdown.--horizontal-scroll::-webkit-scrollbar, .ego-form__field.--select-react .ego-form__field__select-dropdown.--horizontal-scroll::-webkit-scrollbar {
  height: 0.5em;
}
.ego-form__field.--select .ego-form__field__select-dropdown::-webkit-scrollbar-track, .ego-form__field.--select-react .ego-form__field__select-dropdown::-webkit-scrollbar-track {
  box-shadow: none;
  -webkit-box-shadow: none;
}
.ego-form__field.--select .ego-form__field__select-dropdown::-webkit-scrollbar-thumb, .ego-form__field.--select-react .ego-form__field__select-dropdown::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  outline: 0;
  border-radius: 5px;
}
.ego-form__field.--select .ego-form__field__select-dropdown ul, .ego-form__field.--select-react .ego-form__field__select-dropdown ul {
  display: flex;
  flex-direction: column;
}
.ego-form__field.--select .ego-form__field__select-dropdown ul li.option, .ego-form__field.--select-react .ego-form__field__select-dropdown ul li.option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 38px;
  padding: 0 16px;
  width: 100%;
}
.ego-form__field.--select .ego-form__field__select-dropdown ul li.option:not(.--disabled):hover, .ego-form__field.--select .ego-form__field__select-dropdown ul li.option:not(.--selected):hover, .ego-form__field.--select-react .ego-form__field__select-dropdown ul li.option:not(.--disabled):hover, .ego-form__field.--select-react .ego-form__field__select-dropdown ul li.option:not(.--selected):hover {
  background-color: #F7F7F7;
}
.ego-form__field.--select .ego-form__field__select-dropdown ul li.option.--selected, .ego-form__field.--select-react .ego-form__field__select-dropdown ul li.option.--selected {
  background-color: #E8E8E8 !important;
  color: #000000;
}
.ego-form__field.--select .ego-form__field__select-dropdown ul li.option.--disabled, .ego-form__field.--select-react .ego-form__field__select-dropdown ul li.option.--disabled {
  color: #F7F7F7;
  pointer-events: none;
  cursor: not-allowed;
}
.ego-form__field.--select.--open .ego-form__field__select-dropdown, .ego-form__field.--select-react.--open .ego-form__field__select-dropdown {
  opacity: 1;
  pointer-events: all;
}
.ego-form__field.--select.--drop-up .ego-form__field__select-dropdown, .ego-form__field.--select-react.--drop-up .ego-form__field__select-dropdown {
  position: absolute;
  width: 100%;
  box-shadow: 0px -4px 8px -2px rgba(16, 24, 40, 0.1), 0px -2px 4px -2px rgba(16, 24, 40, 0.06);
}
.ego-form__field.--select-text {
  display: inline-block;
  position: relative;
  z-index: 0;
  width: auto;
  height: 25px;
  margin-bottom: 0;
  padding: 0;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0em;
  font-family: "Nobel", Arial, Helvetica, sans-serif;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  border: 0;
  z-index: 5;
}
.ego-form__field.--select-text.--has-error {
  border-color: #AD0B00 !important;
}
.ego-form__field.--select-text .caret {
  content: "";
  display: inline-block;
  width: 22px;
  height: 22px;
  background: url("../images/dropdown-arrow.svg") no-repeat center center;
  background-size: contain;
  transform: rotate(180deg);
}
.ego-form__field.--select-text .ego-form__field__select-display {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0;
  transition: all 0.2s ease-in-out;
  color: #8A8D8F;
}
.ego-form__field.--select-text .ego-form__field__select-display .value-placeholder {
  font-size: 16px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: normal;
  text-decoration: underline;
  text-underline-offset: 4px;
  text-transform: uppercase;
}
.ego-form__field.--select-text .ego-form__field__select-dropdown {
  position: relative;
  z-index: 1;
  background-color: #ffffff;
  box-shadow: 0px 4px 8px -2px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
  padding: 8px 0;
  margin-left: -3px;
  margin-top: 8px;
  max-height: 160px;
  overflow-x: auto;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.2s ease-in-out, color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}
.ego-form__field.--select-text .ego-form__field__select-dropdown::-webkit-scrollbar {
  width: 0.5em;
  padding-left: 10px;
}
.ego-form__field.--select-text .ego-form__field__select-dropdown.--horizontal-scroll::-webkit-scrollbar {
  height: 0.5em;
}
.ego-form__field.--select-text .ego-form__field__select-dropdown::-webkit-scrollbar-track {
  box-shadow: none;
  -webkit-box-shadow: none;
}
.ego-form__field.--select-text .ego-form__field__select-dropdown::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  outline: 0;
  border-radius: 5px;
}
.ego-form__field.--select-text .ego-form__field__select-dropdown ul {
  display: flex;
  flex-direction: column;
}
.ego-form__field.--select-text .ego-form__field__select-dropdown ul li.option {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 38px;
  padding: 0 16px;
  width: 100%;
}
.ego-form__field.--select-text .ego-form__field__select-dropdown ul li.option:not(.--disabled):hover, .ego-form__field.--select-text .ego-form__field__select-dropdown ul li.option:not(.--selected):hover {
  background-color: #f7f7f7;
}
.ego-form__field.--select-text .ego-form__field__select-dropdown ul li.option.--selected {
  background-color: #8A8D8F !important;
  color: #FFFFFF;
}
.ego-form__field.--select-text .ego-form__field__select-dropdown ul li.option.--disabled {
  color: #F7F7F7;
  pointer-events: none;
  cursor: not-allowed;
}
.ego-form__field.--select-text:hover .ego-form__field__select-display {
  color: #454545;
}
.ego-form__field.--select-text.--open {
  z-index: 6;
}
.ego-form__field.--select-text.--open .caret {
  transform: rotate(180deg);
}
.ego-form__field.--select-text.--open .ego-form__field__select-dropdown {
  opacity: 1;
  pointer-events: all;
}
.ego-form__field.--select-text.--drop-up .ego-form__field__select-dropdown {
  position: absolute;
  inset: auto 0 calc(100% + 10px) 0;
}
.ego-form__field.--select:first-child {
  z-index: 6;
}
.ego-form__field__toggle-visibility {
  position: absolute;
  z-index: 1;
  right: calc(1.1rem - 5px);
  top: 11px;
  border: 0;
  background: none;
  outline: none;
  padding: 4px;
  margin: 0 auto;
  cursor: pointer;
}
.ego-form__field__toggle-visibility .show {
  width: 24px;
  display: none;
}
.ego-form__field__toggle-visibility .hide {
  width: 24px;
  display: block;
  margin-top: -3px;
}
.ego-form__field__toggle-visibility.--hide .hide {
  display: none;
}
.ego-form__field__toggle-visibility.--hide .show {
  display: block;
}
.ego-form__field__instruction {
  display: block;
  padding: 0 1rem;
  margin: 0;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.875;
  letter-spacing: normal;
}
.ego-form__field__search {
  position: relative;
}
.ego-form__field__search .placeholder {
  position: absolute;
  inset: 0;
  width: 100%;
  padding: 0.8rem;
  font-size: 20px;
  font-weight: 300;
  line-height: normal;
  letter-spacing: normal;
  color: #F7F7F7;
  pointer-events: none;
}
.ego-form__field__search .placeholder svg {
  display: inline-block;
  margin-left: 5px;
}
.ego-form__field__search .placeholder svg * {
  fill: #F7F7F7;
}
.ego-form__field__search input {
  text-align: center;
  width: 100%;
  padding: 0.8rem;
  border: 2px solid #F7F7F7;
  font-size: 20px;
  font-weight: 300;
  line-height: normal;
  letter-spacing: normal;
}
.ego-form__field__search input::-webkit-input-placeholder {
  color: transparent;
}
.ego-form__field__search input::-moz-placeholder {
  color: transparent;
}
.ego-form__field__search input:-moz-placeholder {
  color: transparent;
}
.ego-form__field__search input:focus + .placeholder, .ego-form__field__search input:not(:placeholder-shown) + .placeholder {
  display: none;
}
.ego-form__field__search .empty {
  display: none;
  margin-bottom: 0;
  padding: 1rem;
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
  letter-spacing: normal;
  color: #F7F7F7;
}
.ego-form__field__search .empty.--show {
  display: block;
}
.ego-form__footer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
@media (max-width: 575px) {
  .ego-form__field__input, .ego-form__field__textarea, .ego-form__field__select, .ego-form__field__radio-label, .ego-form__field__file {
    padding: 1.13rem 0.9rem 0.4rem 0.9rem;
    font-size: 16px;
    font-weight: 300;
    line-height: normal;
    letter-spacing: normal;
  }
  .ego-form__field__textarea {
    padding-top: 0.8rem;
  }
  .ego-form__field.--textarea textarea {
    margin-top: 1.2rem;
    font-size: 18px;
    font-weight: 300;
    line-height: normal;
    letter-spacing: normal;
  }
  .ego-form__field.--textarea .ego-form__field__error {
    min-height: 48px;
  }
  .ego-form__field.--textarea .ego-form__field__footer {
    grid-template-columns: auto 80px;
  }
  .ego-form__field.--textarea .ego-form__field__footer .ego-form__field__length-counter {
    font-size: 10px;
    font-weight: 300;
    line-height: 1;
    letter-spacing: normal;
  }
  .ego-form__field[data-type=file] .ego-form__field__file {
    padding: 0.765rem 0.9rem 0.765rem 0.9rem;
  }
  .ego-form__field.--optional::after {
    font-size: 10px;
    font-weight: 300;
    line-height: 1;
    letter-spacing: normal;
    top: 1.25rem;
    right: 1rem;
  }
  .ego-form__field__instruction {
    font-size: 14px;
    font-weight: 300;
    line-height: 1.4285714286;
    letter-spacing: normal;
    margin-top: 8px;
  }
  .ego-form__message p {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.7777777778;
    letter-spacing: normal;
  }
  .ego-form .ego-form__field__select-dropdown {
    position: fixed !important;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5) !important;
    z-index: 10;
    margin: 0;
    max-height: none !important;
  }
  .ego-form .ego-form__field__select-dropdown ul {
    background-color: #FFFFFF;
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 13px 0px 62px 0px;
    border-radius: 16px 16px 0px 0px;
    z-index: 11;
    width: 100vw;
    text-transform: uppercase;
    overflow-y: scroll;
  }
  .ego-form .ego-form__field__select-dropdown ul::-webkit-scrollbar {
    width: 0.5em;
    padding-left: 10px;
  }
  .ego-form .ego-form__field__select-dropdown ul.--horizontal-scroll::-webkit-scrollbar {
    height: 0.5em;
  }
  .ego-form .ego-form__field__select-dropdown ul::-webkit-scrollbar-track {
    box-shadow: none;
    -webkit-box-shadow: none;
  }
  .ego-form .ego-form__field__select-dropdown ul::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);
    outline: 0;
    border-radius: 5px;
  }
  .ego-form .ego-form__field__select-dropdown ul li {
    padding-left: 24px !important;
    padding-right: 24px !important;
  }
}

.ego-modal {
  display: none;
  position: fixed;
  z-index: 10;
  inset: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border: 0;
  background-color: rgba(0, 0, 0, 0.8);
  overflow-y: auto;
}
.ego-modal__wrapper {
  display: grid;
  align-items: flex-start;
  width: 100%;
  min-height: 100%;
  padding: 20px;
}
.ego-modal__box {
  position: relative;
  top: 54px;
  display: block;
  margin: 0 auto 80px;
  width: 100%;
  max-width: 700px;
  background-color: #fff;
  border-radius: 16px;
  cursor: default;
  transition: top 0.2s ease-in-out;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.3333333333;
  letter-spacing: normal;
}
.ego-modal__box.--small {
  max-width: 400px;
}
.ego-modal__box .padding-24 {
  padding: 24px;
}
.ego-modal__box__body {
  position: relative;
}
.ego-modal__box__body--page {
  padding: 24px;
  display: none;
}
.ego-modal__box__body--page.--active {
  display: block;
}
.ego-modal__box__body--page .ego-modal__box__close-btn {
  position: absolute;
  top: 25px;
  right: 25px;
  border: 0;
  background: none;
  outline: none;
  padding: 5px;
  margin: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ego-modal__box__body--page .ego-modal__box__close-btn .times {
  width: 14px;
  height: 14px;
}
.ego-modal__box__body--page .ego-modal__box__close-btn .times * {
  transition: stroke 0.2s ease-in-out;
  stroke: #454545;
}
.ego-modal__box__body--page .ego-modal__box__close-btn:hover .times * {
  stroke: #000000;
}
.ego-modal__box__body--page .ego-modal__box__back-btn {
  position: absolute;
  top: 25px;
  left: 25px;
  border: 0;
  background: none;
  outline: none;
  padding: 5px;
  margin: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.ego-modal__box__body--page .ego-modal__box__back-btn .chevron-left {
  width: 8px;
  height: 14px;
}
.ego-modal__box__body--page .ego-modal__box__back-btn .chevron-left * {
  transition: stroke 0.2s ease-in-out;
  stroke: #454545;
}
.ego-modal__box__body--page .ego-modal__box__back-btn:hover .chevron-left * {
  stroke: #000000;
}
.ego-modal__box__content-header {
  text-align: center;
  margin-bottom: 16px;
}
.ego-modal__box__content-header .icon {
  margin-bottom: 16px;
}
.ego-modal__box__content-header p {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.4285714286;
  letter-spacing: normal;
  color: #8A8D8F;
  margin: 1rem 0;
}
.ego-modal__content-block {
  margin-bottom: 30px;
}
.ego-modal.--open .ego-modal__box {
  top: 44px;
}
@media (max-width: 719px) {
  .ego-modal {
    padding: 0;
  }
  .ego-modal__wrapper {
    align-items: end;
  }
  .ego-modal__box {
    margin: 80px auto 0;
    bottom: 28px;
    top: auto;
  }
  .ego-modal.--open .ego-modal__box {
    top: auto;
  }
}

h1 {
  font-size: 40px;
  font-weight: 400;
  line-height: 1.1;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}
@media (max-width: 719px) {
  h1 {
    font-size: 32px;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: 0.05em;
  }
}

h2 {
  font-size: 40px;
  font-weight: 400;
  line-height: 1.1;
  letter-spacing: 0em;
}
@media (max-width: 719px) {
  h2 {
    font-size: 32px;
    font-weight: 400;
    line-height: 1.25;
    letter-spacing: 0em;
  }
}

h3 {
  font-size: 30px;
  font-weight: 400;
  line-height: 1.3333333333;
  letter-spacing: 0.05em;
}

h4 {
  font-size: 26px;
  font-weight: 400;
  line-height: 1.3846153846;
  letter-spacing: 0.05em;
}
@media (max-width: 719px) {
  h4 {
    font-size: 24px;
    font-weight: 400;
    line-height: 1.5;
    letter-spacing: 0.05em;
  }
}

h5 {
  font-size: 22px;
  font-weight: 400;
  line-height: 1.3636363636;
  letter-spacing: 0.05em;
}
@media (max-width: 719px) {
  h5 {
    font-size: 22px;
    font-weight: 400;
    line-height: 1.4545454545;
    letter-spacing: 0.05em;
  }
}

p {
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5555555556;
  letter-spacing: normal;
}

.--text-l {
  font-size: 18px;
  font-weight: 400;
  line-height: 2;
  letter-spacing: 0em;
}

.--text-m {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0em;
}

.--text-s {
  font-size: 14px;
  font-weight: 400;
  line-height: 1.2857142857;
  letter-spacing: 0.05em;
}

.--text-xs {
  font-size: 12px;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0em;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.header {
  position: fixed;
  top: 0;
  z-index: 10;
  background-color: #FFFFFF;
  color: #000000;
  padding: 20px 0px;
  width: 100%;
  transition: all 0.2s ease-in-out;
}
@media (min-width: 1024px) {
  .header {
    height: 76px;
  }
}
.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (min-width: 1024px) {
  .header-container {
    height: 44px;
    margin-top: -9px;
  }
}
.header-container__logo-corporative {
  display: flex;
  align-self: center;
}
.header-container__logo-corporative a {
  display: flex;
}
.header-container__logo-corporative a img {
  filter: invert(1);
  width: 140px;
  position: relative;
  z-index: 3;
  height: 100%;
}
.header-container__logo-corporative a p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.125;
  letter-spacing: 0.01em;
  padding-left: 13px;
  margin-top: auto;
  margin-bottom: auto;
}
.header-container__navigation-corporative ul {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  padding-top: 14px;
}
.header-container__navigation-corporative ul li {
  display: flex;
  align-items: flex-start;
}
.header-container__navigation-corporative ul a {
  padding-bottom: 14px;
}
.header-container__navigation-corporative ul a.--active,
.header-container__navigation-corporative ul a.active,
.header-container__navigation-corporative ul a.ancestor:not(.is_root) {
  border-bottom: 2px solid #000000;
  font-weight: 700;
}
.header-container__language-corporative {
  display: flex;
  align-items: center;
}
.header-container__language-corporative .lang.ego-form__field {
  width: 60px;
  height: 24px;
  padding: 0 10px;
  margin-left: 12px;
}
.header-container__language-corporative .lang.ego-form__field .ego-form__field__select-dropdown {
  width: calc(100% + 20px);
  margin-left: -10px;
  padding: 0;
}
.header-container__language-corporative .lang.ego-form__field .ego-form__field__select-dropdown ul li.option {
  padding: 0 10px;
  transition: background-color 0.2s ease-in-out;
}
.header-container__language-corporative .lang.ego-form__field .ego-form__field__select-dropdown ul li.option:not(.--selected):hover {
  background-color: #454545 !important;
}
.header-container__logo-store {
  display: flex;
  align-items: center;
  align-self: center;
  position: relative;
  z-index: 3;
}
.header-container__logo-store p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.125;
  letter-spacing: 0.01em;
  padding-left: 13px;
}
.header-container__logo-store img {
  filter: invert(1);
  width: 140px;
}
.header-container__logo-store .store-text {
  margin-top: -2px;
}
.header-container__navigation-store ul {
  display: flex;
  align-items: flex-start;
  gap: 24px;
  margin-top: 1rem;
}
.header-container__navigation-store ul li {
  display: flex;
  align-items: flex-start;
}
.header-container__navigation-store ul a {
  padding-bottom: 14px;
  border-bottom: 2px solid transparent;
}
.header-container__navigation-store ul a.--active,
.header-container__navigation-store ul a.active,
.header-container__navigation-store ul a.ancestor:not(.is_root) {
  border-bottom: 2px solid #000000;
  font-weight: 700;
}
.header-container__language-store {
  display: flex;
  align-items: flex-start;
}
.header-container__language-store a {
  margin-right: 22px;
  margin-top: 1rem;
  padding-bottom: 14px;
  border-bottom: 2px solid transparent;
}
.header-container__language-store a.--active {
  border-bottom: 2px solid #000000;
  font-weight: 700;
}
.header-container__mobile {
  display: none;
}
.header-container a {
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.05em;
  text-transform: uppercase;
}
.header-container .new-tag {
  font-size: 8px;
  font-weight: 600;
  line-height: 1.25;
  letter-spacing: normal;
  background-color: #E72222;
  padding: 3px 5px;
  border-radius: 1px;
  margin-left: 8px;
  color: #FFFFFF;
}
.header-container .ego-form__field {
  margin-bottom: 0;
}
.header-container .ego-form__field.--select .ego-form__field__select-display {
  font-size: 12px;
  font-weight: 400;
  line-height: 2;
  letter-spacing: normal;
  padding: 0;
}
.header-container .ego-form__field.--select .ego-form__field__select-display .value-placeholder {
  margin-top: 15px;
  font-size: 12px;
  font-weight: 400;
  line-height: 2;
  letter-spacing: normal;
  margin: 0;
}
.header-container .ego-form__field.--select .ego-form__field__select-dropdown ul li.option {
  font-size: 12px;
  font-weight: 400;
  line-height: 2;
  letter-spacing: normal;
}
@media (max-width: 1270px) {
  .header-container__navigation-store, .header-container__language-store {
    display: none;
  }
}
@media (max-width: 1280px) {
  .header-container__navigation-corporative, .header-container__language-corporative {
    display: none;
  }
}

.header__menu-button {
  display: none;
}
@media (max-width: 1270px) {
  .header__menu-button-store {
    display: block;
  }
}
@media (max-width: 1280px) {
  .header__menu-button-corporative {
    display: block;
  }
}
.header__menu-button .header-toggler {
  font-weight: 700;
  position: relative;
  z-index: 3;
  cursor: pointer;
  border: none;
  background: none;
  padding: 0;
  margin: 0;
  margin-left: 1.5rem;
  display: flex;
  align-items: center;
}
.header__menu-button .header-toggler:hover, .header__menu-button .header-toggler:focus, .header__menu-button .header-toggler:active {
  outline: none;
}
.header__menu-button .header-toggler .toggler-text {
  font-size: 0.7rem;
  color: #000000;
}
.header__menu-button .header-toggler .toggler-text:before, .header__menu-button .header-toggler .toggler-text:after {
  transition: all 0.4s ease-in-out;
}
.header__menu-button .header-toggler .toggler-text:before {
  content: "";
  visibility: visible;
  opacity: 1;
  position: absolute;
}
.header__menu-button .header-toggler .toggler-text:after {
  content: "";
  visibility: hidden;
  opacity: 0;
}
@media (max-width: 1270px) {
  .header__menu-button .header-toggler .toggler-text {
    display: none;
  }
}
@media (max-width: 1270px) {
  .header__menu-button .header-toggler.active .toggler-text {
    display: block;
  }
}
.header__menu-button .header-toggler .toggler-icon {
  width: 20px;
  height: 20px;
  position: relative;
  margin-left: 0.5rem;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
}
.header__menu-button .header-toggler .toggler-icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #000000;
  border-radius: 5px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}
.header__menu-button .header-toggler .toggler-icon span:nth-child(1) {
  top: 3px;
}
.header__menu-button .header-toggler .toggler-icon span:nth-child(2) {
  top: 8.5px;
  height: 2.5px;
}
.header__menu-button .header-toggler .toggler-icon span:nth-child(3) {
  top: 15px;
  height: 2px;
}
.header__menu-button .header-toggler.active .toggler-text:before {
  visibility: hidden;
  opacity: 0;
}
.header__menu-button .header-toggler.active .toggler-text:after {
  visibility: visible;
  opacity: 1;
}
.header__menu-button .header-toggler.active .toggler-icon span:nth-child(1) {
  top: 8.5px;
  transform: rotate(135deg);
}
.header__menu-button .header-toggler.active .toggler-icon span:nth-child(2) {
  opacity: 0;
}
.header__menu-button .header-toggler.active .toggler-icon span:nth-child(3) {
  top: 8.5px;
  transform: rotate(-135deg);
}

.header__secondary-nav {
  position: fixed;
  z-index: 2;
  right: 0;
  top: 0;
  bottom: 0;
  min-width: 20rem;
  width: 50%;
  background-color: #FFFFFF !important;
  color: #000000 !important;
  visibility: hidden;
  transform: translateX(100%);
}
.header__secondary-nav .header__secondary-nav__list {
  background-color: #FFFFFF !important;
  color: #000000 !important;
}
.header__secondary-nav .header__secondary-nav__list li {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.05em;
}
.header__secondary-nav .header__secondary-nav__list li a {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.header__secondary-nav .language-options {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
}
.header__secondary-nav .language-options.active {
  display: block;
}
.header__secondary-nav .language-options__content {
  background-color: #FFFFFF;
  position: fixed;
  bottom: 0;
  left: 0;
  padding-top: 8px;
  border-radius: 16px 16px 0px 0px;
  z-index: 11;
  width: 100vw;
  text-transform: uppercase;
  overflow-y: scroll;
  row-gap: 0 !important;
}
.header__secondary-nav .language-options__content::-webkit-scrollbar {
  width: 0.5em;
  padding-left: 10px;
}
.header__secondary-nav .language-options__content.--horizontal-scroll::-webkit-scrollbar {
  height: 0.5em;
}
.header__secondary-nav .language-options__content::-webkit-scrollbar-track {
  box-shadow: none;
  -webkit-box-shadow: none;
}
.header__secondary-nav .language-options__content::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  outline: 0;
  border-radius: 5px;
}
.header__secondary-nav .language-options__content li {
  color: #000000;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5555555556;
  letter-spacing: normal;
  text-transform: none;
}
.header__secondary-nav .language-options__content li .icon-selected {
  display: none;
}
.header__secondary-nav .language-options__content li.--selected .icon-selected {
  display: block;
}
.header__secondary-nav .language-options__content .text {
  display: flex;
  justify-content: space-between;
  padding-left: 24px;
  padding-right: 24px;
  margin-bottom: 20px;
  color: #000000;
}
.header__secondary-nav .language-options__content .text div {
  font-size: 12px;
  color: #000000;
}
.header__secondary-nav .language-options__content .icon {
  margin-right: auto;
  margin-left: auto;
  margin-bottom: 21px;
}
@media (max-width: 1270px) {
  .header__secondary-nav.open {
    visibility: visible;
    transform: translateX(0);
    height: 100vh;
    min-width: auto;
    transition: all 0.2s ease-in-out;
  }
  .header__secondary-nav__list {
    position: absolute;
    top: 4.5rem;
    bottom: 0;
    right: 0;
    left: 0;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #FFFFFF;
  }
  .header__secondary-nav__list ul {
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    row-gap: 40px;
  }
  .header__secondary-nav__list ul li {
    padding: 0px 34px;
  }
}
@media (max-width: 1023px) {
  .header__secondary-nav {
    width: 100%;
  }
}

.header-placeholder {
  height: 104px;
  background-color: #FFFFFF;
}
@media (max-width: 1023px) {
  .header-placeholder {
    height: 65px;
  }
}

body.dark .header,
body.background-black .header {
  background-color: #000000;
  color: #FFFFFF;
  border-bottom: none;
  padding: 20px 0px;
}
body.dark .header-container__logo-corporative img,
body.background-black .header-container__logo-corporative img {
  filter: invert(0);
}
body.dark .header-container__navigation-corporative ul a.--active,
body.dark .header-container__navigation-corporative ul a.active,
body.dark .header-container__navigation-corporative ul a.ancestor:not(.is_root),
body.background-black .header-container__navigation-corporative ul a.--active,
body.background-black .header-container__navigation-corporative ul a.active,
body.background-black .header-container__navigation-corporative ul a.ancestor:not(.is_root) {
  border-bottom: 2px solid #FFFFFF;
}
body.dark .header .ego-form__field.--select .ego-form__field__select-display,
body.background-black .header .ego-form__field.--select .ego-form__field__select-display {
  color: #FFFFFF;
}
body.dark .header .ego-form__field.--select .ego-form__field__select-dropdown,
body.background-black .header .ego-form__field.--select .ego-form__field__select-dropdown {
  background-color: #000000;
  box-shadow: none;
  border: 1px solid #191919;
}
body.dark .header .ego-form__field.--select .ego-form__field__select-dropdown ul li.option,
body.background-black .header .ego-form__field.--select .ego-form__field__select-dropdown ul li.option {
  color: #FFFFFF;
}
body.dark .header .ego-form__field.--select .ego-form__field__select-dropdown ul li.option.--selected,
body.background-black .header .ego-form__field.--select .ego-form__field__select-dropdown ul li.option.--selected {
  background-color: #191919;
}
body.dark .header .ego-form__field.--select .caret,
body.background-black .header .ego-form__field.--select .caret {
  filter: invert(1);
}
body.dark .header__menu-button .header-toggler .toggler-icon span,
body.background-black .header__menu-button .header-toggler .toggler-icon span {
  background: #FFFFFF;
}
body.dark .header__secondary-nav,
body.background-black .header__secondary-nav {
  background-color: #000000;
  color: #FFFFFF;
}
@media (max-width: 1270px) {
  body.dark .header__secondary-nav__list,
  body.background-black .header__secondary-nav__list {
    background-color: #000000;
  }
}
body.dark .header-placeholder,
body.background-black .header-placeholder {
  background-color: #000000;
}

.header.header-black {
  background-color: #000000;
  color: #FFFFFF;
}
.header.header-black .header-container__logo-corporative img {
  filter: invert(0);
}
.header.header-black .header-container__navigation-corporative ul li a {
  color: #FFFFFF;
  border-color: #FFFFFF;
}
.header.header-black .header-container__navigation-corporative ul a.--active,
.header.header-black .header-container__navigation-corporative ul a.active,
.header.header-black .header-container__navigation-corporative ul a.ancestor:not(.is_root) {
  border-color: 2px solid #FFFFFF;
}
.header.header-black .header-container__language-corporative .ego-form__field__select-display .value-placeholder {
  color: #FFFFFF;
}
.header.header-black .header-container__language-corporative .ego-form__field__select-display .caret {
  filter: invert(1);
}
.header.header-black .header-container__language-corporative .ego-form__field__select-display .ego-form__field__select-dropdown {
  background-color: #000000;
}
.header.header-black .header-container__language-corporative .ego-form__field__select-display .ego-form__field__select-dropdown ul li.option {
  color: #FFFFFF;
}
.header.header-black .header-container__language-corporative .ego-form__field__select-display .ego-form__field__select-dropdown ul li.option.--selected {
  background-color: #191919 !important;
}
.header.header-black .header-container .header__menu-button-corporative .header-toggler .toggler-icon span {
  background-color: #FFFFFF;
}
.header.header-black .header-container .header__menu-button-corporative .header-toggler.active .toggler-icon span {
  background-color: #000000 !important;
}
.header.header-white {
  background-color: #FFFFFF !important;
  color: #000000 !important;
}
.header.header-white .header-container__logo-corporative img {
  filter: invert(1);
}
.header.header-white .header-container__navigation-corporative ul li a {
  color: #000000;
  border-color: #000000;
}
.header.header-white .header-container__navigation-corporative ul a.--active,
.header.header-white .header-container__navigation-corporative ul a.active,
.header.header-white .header-container__navigation-corporative ul a.ancestor:not(.is_root) {
  border-color: 2px solid #000000;
}
.header.header-white .header-container__language-corporative .ego-form__field__select-display .value-placeholder {
  color: #000000;
}
.header.header-white .header-container__language-corporative .ego-form__field__select-display .caret {
  filter: invert(0) !important;
}
.header.header-white .header-container__language-corporative .ego-form__field__select-display .ego-form__field__select-dropdown {
  background-color: #FFFFFF;
}
.header.header-white .header-container__language-corporative .ego-form__field__select-display .ego-form__field__select-dropdown ul li.option {
  color: #000000;
  background-color: #FFFFFF !important;
}
.header.header-white .header-container__language-corporative .ego-form__field__select-display .ego-form__field__select-dropdown ul li.option.--selected {
  background-color: #191919 !important;
}
.header.header-white .header-container .header__menu-button-corporative .header-toggler .toggler-icon span {
  background-color: #000000;
}
.header.header-white .header-container .header__menu-button-corporative .header-toggler.active .toggler-icon span {
  background-color: #000000 !important;
}
.header .cart-items {
  background-color: #000000;
  color: #FFFFFF;
  padding: 0.25rem 0.5rem;
  border-radius: 50px;
  font-weight: 700;
  margin-left: 0.25rem;
}

.footer {
  background-color: #191919;
  color: #FFFFFF;
  padding-top: 64px;
  padding-bottom: 64px;
  position: relative;
}
.footer-grid {
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
}
@media (min-width: 720px) {
  .footer-grid {
    grid-template-columns: repeat(5, 1fr 16px) 1fr;
  }
}
@media (min-width: 1024px) {
  .footer-grid {
    grid-template-columns: repeat(11, 1fr 16px) 1fr;
  }
}
.footer-grid__contact_corporative {
  grid-row: 1;
  grid-column: 1/-1;
  margin-bottom: 120px;
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
}
@media (min-width: 720px) {
  .footer-grid__contact_corporative {
    grid-template-columns: repeat(5, 1fr 16px) 1fr;
  }
}
@media (min-width: 1024px) {
  .footer-grid__contact_corporative {
    grid-template-columns: repeat(11, 1fr 16px) 1fr;
  }
}
.footer-grid__contact_corporative h2 {
  align-self: start;
  grid-column: 1/span 12;
}
.footer-grid__contact_corporative h2.mobile {
  display: none;
}
.footer-grid__contact_corporative div {
  grid-column: 14/-1;
}
.footer-grid__contact_corporative div p {
  margin-bottom: 40px;
}
.footer-grid__social_corporative {
  grid-row: 2;
  grid-column: 1/-1;
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
  margin-bottom: 72px;
}
@media (min-width: 720px) {
  .footer-grid__social_corporative {
    grid-template-columns: repeat(5, 1fr 16px) 1fr;
  }
}
@media (min-width: 1024px) {
  .footer-grid__social_corporative {
    grid-template-columns: repeat(11, 1fr 16px) 1fr;
  }
}
.footer-grid__social_corporative-list {
  grid-column: 1/span 12;
  display: flex;
  gap: 40px;
}
.footer-grid__social_corporative-list p {
  margin-bottom: 12px;
}
.footer-grid__social_corporative-list li {
  margin-bottom: 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0em;
}
.footer-grid__social_corporative-buttons {
  grid-column: 14/-1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: start;
}
.footer-grid__social_corporative-buttons p {
  margin-bottom: 16px;
}
.footer-grid__social_corporative-buttons a {
  margin-left: 16px;
  text-decoration: none;
  color: inherit;
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}
.footer-grid__social_corporative-buttons a img {
  height: min-content;
}
.footer-grid__social_corporative-buttons a:hover {
  background-color: #000000;
}
.footer-grid__social_corporative-buttons a:hover img {
  filter: invert(1);
}
.footer-grid__terms_corporative {
  grid-row: 3;
  grid-column: 1/-1;
  border-top: 1px solid #8A8D8F;
  padding-top: 32px;
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
}
@media (min-width: 720px) {
  .footer-grid__terms_corporative {
    grid-template-columns: repeat(5, 1fr 16px) 1fr;
  }
}
@media (min-width: 1024px) {
  .footer-grid__terms_corporative {
    grid-template-columns: repeat(11, 1fr 16px) 1fr;
  }
}
.footer-grid__terms_corporative-top {
  grid-row: 1;
  grid-column: 1/-1;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.footer-grid__terms_corporative-top .copyright {
  color: #D8D8D8;
  font-weight: 300;
}
.footer-grid__terms_corporative-top .links {
  display: flex;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: normal;
}
.footer-grid__terms_corporative-top .links li {
  margin-left: 12px;
  text-decoration: underline;
}
.footer-grid__terms_corporative-top ul {
  flex-wrap: wrap;
}
.footer-grid__terms_corporative-bottom {
  grid-row: 2;
  grid-column: 1/-1;
}
.footer-grid__terms_corporative-bottom p {
  font-weight: 300;
  color: #D8D8D8;
}
.footer-grid__contact_store {
  grid-row: 1;
  grid-column: 1/-1;
  margin-bottom: 78px;
  display: flex;
  flex-direction: column;
  display: grid;
  height: auto;
  align-items: center;
  grid-template-columns: repeat(3, 1fr 16px) 1fr;
}
@media (min-width: 720px) {
  .footer-grid__contact_store {
    grid-template-columns: repeat(5, 1fr 16px) 1fr;
  }
}
@media (min-width: 1024px) {
  .footer-grid__contact_store {
    grid-template-columns: repeat(11, 1fr 16px) 1fr;
  }
}
.footer-grid__contact_store span {
  grid-column: 1/span 10;
}
.footer-grid__contact_store .footer-grid__social-buttons {
  grid-column: 14/-1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: start;
}
.footer-grid__contact_store .footer-grid__social-buttons p {
  margin-bottom: 16px;
}
.footer-grid__contact_store .footer-grid__social-buttons a {
  margin-left: 16px;
  text-decoration: none;
  color: inherit;
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
}
.footer-grid__contact_store .footer-grid__social-buttons a img {
  height: min-content;
}
.footer-grid__contact_store .footer-grid__social-buttons a:hover {
  background-color: #000000;
}
.footer-grid__contact_store .footer-grid__social-buttons a:hover img {
  filter: invert(1);
}
.footer-grid__contact_store p {
  font-weight: 300;
}
.footer-grid__social_store {
  grid-row: 1;
  grid-column: 14/-1;
  margin-bottom: 78px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  align-self: start;
}
.footer-grid__social_store p {
  margin-bottom: 16px;
}
.footer-grid__social_store a {
  margin-left: 16px;
  text-decoration: none;
  color: inherit;
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 50%;
}
.footer-grid__social_store a img {
  height: min-content;
}
.footer-grid__social_store a:hover {
  background-color: #000000;
}
.footer-grid__social_store a:hover img {
  filter: invert(1);
}
.footer-grid__terms_store {
  grid-row: 3;
  grid-column: 1/-1;
  border-top: 1px solid #8A8D8F;
  padding-top: 32px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
.footer-grid__terms_store .copyright {
  color: #D8D8D8;
  font-weight: 300;
}
.footer-grid__terms_store .links {
  display: flex;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: normal;
}
.footer-grid__terms_store .links li {
  margin-left: 12px;
  text-decoration: underline;
}
.footer-grid__terms_store ul {
  flex-wrap: wrap;
}
@media (max-width: 1023px) {
  .footer-grid__contact_corporative {
    margin-bottom: 40px;
  }
  .footer-grid__contact_corporative h2 {
    grid-column: 1/-1;
    grid-row: 1;
    margin-bottom: 16px;
  }
  .footer-grid__contact_corporative h2.mobile {
    display: block;
  }
  .footer-grid__contact_corporative h2.desktop {
    display: none;
  }
  .footer-grid__contact_corporative div {
    grid-column: 1/-1;
    grid-row: 2;
  }
  .footer-grid__contact_corporative div p {
    margin-bottom: 16px;
  }
  .footer-grid__social_corporative {
    grid-row: 2;
    grid-column: 1/-1;
    display: grid;
    height: auto;
    align-items: center;
    grid-template-columns: repeat(3, 1fr 16px) 1fr;
    margin-bottom: 72px;
  }
}
@media (max-width: 1023px) and (min-width: 720px) {
  .footer-grid__social_corporative {
    grid-template-columns: repeat(5, 1fr 16px) 1fr;
  }
}
@media (max-width: 1023px) and (min-width: 1024px) {
  .footer-grid__social_corporative {
    grid-template-columns: repeat(11, 1fr 16px) 1fr;
  }
}
@media (max-width: 1023px) {
  .footer-grid__social_corporative-list {
    grid-column: 1/span 12;
    grid-row: 1;
  }
}
@media (max-width: 1023px) {
  .footer-grid__social_corporative-buttons {
    grid-column: 14/-1;
    grid-row: 1;
  }
}
@media (max-width: 1023px) {
  .footer-grid__terms_corporative-top {
    flex-direction: column-reverse;
  }
  .footer-grid__terms_corporative-top .links {
    margin-bottom: 24px;
  }
  .footer-grid__terms_corporative-top .links li {
    margin-right: 12px;
    margin-left: 0;
  }
}
@media (max-width: 915px) {
  .footer-grid__social_corporative {
    margin-bottom: 40px;
  }
  .footer-grid__social_corporative-list {
    grid-column: 1/-1;
    grid-row: 1;
  }
  .footer-grid__social_corporative-buttons {
    grid-column: 1/-1;
    grid-row: 2;
    align-items: flex-start;
    margin-top: 20px;
  }
  .footer-grid__social_corporative-buttons a {
    margin-left: 0;
    margin-right: 16px;
  }
}
@media (max-width: 719px) {
  .footer-grid__contact_corporative h2 {
    font-size: 26px;
    font-weight: 400;
    line-height: 1.3076923077;
    letter-spacing: -0.02em;
  }
  .footer-grid__social_corporative-list {
    flex-direction: column;
    gap: 24px;
  }
  .footer-grid__contact_store {
    flex-direction: column;
    margin-bottom: 40px;
  }
  .footer-grid__contact_store .footer-grid__social-buttons {
    grid-column: 1/-1;
    grid-row: 2;
    align-items: flex-start;
    margin-top: 20px;
  }
  .footer-grid__contact_store .footer-grid__social-buttons a {
    margin-left: 0;
    margin-right: 16px;
  }
  .footer-grid__terms_store {
    flex-direction: column-reverse;
  }
  .footer-grid__terms_store .links {
    margin-bottom: 24px;
  }
  .footer-grid__terms_store .links li {
    margin-right: 12px;
    margin-left: 0;
  }
}
@media (max-width: 719px) {
  .footer {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.breadcrumb {
  position: relative;
  z-index: 1;
  margin-top: -36px;
  background-color: #FFFFFF;
}
.breadcrumb .wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 36px;
}
.breadcrumb-item {
  color: #000000;
  font-size: 10px;
  font-weight: 300;
  line-height: 2;
  letter-spacing: normal;
  text-transform: uppercase;
}
.breadcrumb-item:not(:last-child)::after {
  content: url("data:image/svg+xml,%3Csvg width='5' height='8' viewBox='0 0 5 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.546875 0.578125L0.4375 0.6875C0.359375 0.765625 0.359375 0.875 0.4375 0.953125L3.48437 4L0.4375 7.0625C0.359375 7.14063 0.359375 7.25 0.4375 7.32812L0.546875 7.4375C0.625 7.51562 0.734375 7.51562 0.8125 7.4375L4.10937 4.14062C4.1875 4.0625 4.1875 3.95312 4.10937 3.875L0.8125 0.578125C0.734375 0.5 0.625 0.5 0.546875 0.578125Z' fill='black'/%3E%3C/svg%3E%0A");
  display: inline-block;
  width: 5px;
  height: 8px;
  margin: 0 10px;
}
@media (max-width: 1023px) {
  .breadcrumb {
    margin-top: 0;
  }
}

.breadcrumb.header-black {
  background-color: #000000 !important;
}
.breadcrumb.header-black .breadcrumb-item {
  color: #FFFFFF !important;
}
.breadcrumb.header-black .breadcrumb-item:not(:last-child)::after {
  content: url("data:image/svg+xml,%3Csvg width='5' height='8' viewBox='0 0 5 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.546875 0.578125L0.4375 0.6875C0.359375 0.765625 0.359375 0.875 0.4375 0.953125L3.48437 4L0.4375 7.0625C0.359375 7.14063 0.359375 7.25 0.4375 7.32812L0.546875 7.4375C0.625 7.51562 0.734375 7.51562 0.8125 7.4375L4.10937 4.14062C4.1875 4.0625 4.1875 3.95312 4.10937 3.875L0.8125 0.578125C0.734375 0.5 0.625 0.5 0.546875 0.578125Z' fill='white'/%3E%3C/svg%3E%0A");
}

.spinner-container {
  position: fixed;
  top: 0;
  box-sizing: border-box;
  z-index: 10000;
  width: 100vw;
  height: 100vh;
  background: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  width: 80px;
  height: 80px;
  clear: both;
  margin: 20px auto;
  border: 8px rgba(0, 0, 0, 0.15) solid;
  border-top: 8px black solid;
  border-radius: 50%;
  animation: spinner-rotation 0.6s infinite linear;
}

@keyframes spinner-rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}