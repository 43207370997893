///////////////////////
// Breakpoints
$screen-sm-min: 576; // Small tablets and large smartphones (landscape view)
$screen-md-min: 720; // Small tablets (portrait view)
$screen-lg-min: 1024; // Tablets and small desktops
$screen-xl-min: 1440; // Large tablets and desktops

$max_width_wrapper: 1440px;
$timming: .2s;

$white: #FFFFFF;
$gray-25: #F7F7F7;
$gray-100: #EEEEEF;
$gray-200: #E8E8E8;
$gray-400: #D8D8D8;
$gray-600: #A7A7A8;
$gray: #8A8D8F;
$gray-700: #454545;
$gray-800: #191919;
$black: #000000;

$red: #E72222;
$blue-light: #73B8DF;

$danger: #AD0B00;
$success: #12B76A;