h1 {
    @include font-size(40, 44, 400, 5);
    text-transform: uppercase;

    @include device-md {
        @include font-size(32, 40, 400, 5);
    }
}

h2 {
    @include font-size(40, 44, 400, 0);

    @include device-md {
        @include font-size(32, 40, 400, 0);
    }
}

h3 {
    @include font-size(30, 40, 400, 5);
}

h4 {
    @include font-size(26, 36, 400, 5);

    @include device-md {
        @include font-size(24, 36, 400, 5);
    }
}

h5 {
    @include font-size(22, 30, 400, 5);

    @include device-md {
        @include font-size(22, 32, 400, 5);
    }
}

p {
    @include font-size(18, 28, 400);
}

.--text-l {
    @include font-size(18, 36, 400, 0);
}

.--text-m {
    @include font-size(16, 24, 400, 0);
}

.--text-s {
    @include font-size(14, 18, 400, 5);
}

.--text-xs {
    @include font-size(12, 18, 300, 0);
}

.fw-300 {
    font-weight: 300 !important;
}

.fw-700 {
    font-weight: 700 !important;
}
