@font-face {
    font-family: "Nobel";
    src: url("../fonts/nobel-black-webfont.woff2") format("woff2"),
        url("../fonts/nobel-black-webfont.woff") format("woff");
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: "Nobel";
    src: url("../fonts/nobel-blackitalic-webfont.woff2") format("woff2"),
        url("../fonts/nobel-blackitalic-webfont.woff") format("woff");
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: "Nobel";
    src: url("../fonts/nobel-bold-webfont.woff2") format("woff2"),
        url("../fonts/nobel-bold-webfont.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "Nobel";
    src: url("../fonts/nobel-bolditalic-webfont.woff2") format("woff2"),
        url("../fonts/nobel-bolditalic-webfont.woff") format("woff");
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: "Nobel";
    src: url("../fonts/nobel-regular-webfont.woff2") format("woff2"),
        url("../fonts/nobel-regular-webfont.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "Nobel";
    src: url("../fonts/nobel-regularitalic-webfont.woff2") format("woff2"),
        url("../fonts/nobel-regularitalic-webfont.woff") format("woff");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "Nobel";
    src: url("../fonts/nobel-book-webfont.woff2") format("woff2"),
        url("../fonts/nobel-book-webfont.woff") format("woff");
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: "Nobel";
    src: url("../fonts/nobel-bookitalic-webfont.woff2") format("woff2"),
        url("../fonts/nobel-bookitalic-webfont.woff") format("woff");
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: "Nobel";
    src: url("../fonts/nobel-light-webfont.woff2") format("woff2"),
        url("../fonts/nobel-light-webfont.woff") format("woff");
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: "Nobel";
    src: url("../fonts/nobel-lightitalic-webfont.woff2") format("woff2"),
        url("../fonts/nobel-lightitalic-webfont.woff") format("woff");
    font-weight: 200;
    font-style: italic;
}
