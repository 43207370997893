.breadcrumb {
    position: relative;
    z-index: 1;
    margin-top: -36px;
    background-color: $white;

    .wrapper {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 36px;
    }

    &-item {
        color: $black;
        @include font-size(10, 20, 300);
        text-transform: uppercase;

        &:not(:last-child)::after {
            content: url("data:image/svg+xml,%3Csvg width='5' height='8' viewBox='0 0 5 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.546875 0.578125L0.4375 0.6875C0.359375 0.765625 0.359375 0.875 0.4375 0.953125L3.48437 4L0.4375 7.0625C0.359375 7.14063 0.359375 7.25 0.4375 7.32812L0.546875 7.4375C0.625 7.51562 0.734375 7.51562 0.8125 7.4375L4.10937 4.14062C4.1875 4.0625 4.1875 3.95312 4.10937 3.875L0.8125 0.578125C0.734375 0.5 0.625 0.5 0.546875 0.578125Z' fill='black'/%3E%3C/svg%3E%0A");
            display: inline-block;
            width: 5px;
            height: 8px;
            margin: 0 10px;
        }
    }

    @include device-lg {
        margin-top: 0;
    }
}

.breadcrumb {
    &.header-black {
        background-color: $black !important;

        .breadcrumb-item {
            color: $white !important;

            &:not(:last-child)::after {
                content: url("data:image/svg+xml,%3Csvg width='5' height='8' viewBox='0 0 5 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.546875 0.578125L0.4375 0.6875C0.359375 0.765625 0.359375 0.875 0.4375 0.953125L3.48437 4L0.4375 7.0625C0.359375 7.14063 0.359375 7.25 0.4375 7.32812L0.546875 7.4375C0.625 7.51562 0.734375 7.51562 0.8125 7.4375L4.10937 4.14062C4.1875 4.0625 4.1875 3.95312 4.10937 3.875L0.8125 0.578125C0.734375 0.5 0.625 0.5 0.546875 0.578125Z' fill='white'/%3E%3C/svg%3E%0A");
            }
        }
    }
}